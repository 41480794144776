import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router';
import { UserContext, UserProvider } from '../contexts/UserContext';
import { CustomerPeopleContext } from '../contexts/CustomerPeopleContext';

export const EnsureCustomerPeople: React.FunctionComponent<any> = ({ match }) => {
  const { user, loading: userLoading } = useContext(UserContext);
  const { people, loading, hydrate, currentCustomer } = useContext(CustomerPeopleContext);

  useEffect(() => {
    if (
      match.params.id &&
      user.company &&
      userLoading.loaded &&
      ((currentCustomer != match.params.id && !loading.loading && !loading.error) ||
        (!loading.loading && !loading.loaded && !loading.error))
    ) {
      hydrate(user.company, match.params.id);
    }
  }, [user, hydrate, loading, userLoading, match, currentCustomer]);

  return null;
};
