import React, { createContext, useState, FC, useMemo } from 'react';
import { Lookups, lookups as getLookups } from '../lib/api/lookups';

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

const defaultLookups: Lookups = {
};

export type LookupContextState = {
  lookups: Lookups;
  loading: Loading;
  hydrate: () => Promise<void>;
};

export const LookupContext = createContext<LookupContextState>({
  lookups: defaultLookups,
  loading: { loading: false, loaded: false, error: undefined },
  hydrate: () => Promise.resolve(),
});

export const LookupProvider: FC = ({ children }) => {
  const [lookups, setLookup] = useState<Lookups>(defaultLookups);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined });

  const value = useMemo(
    () => ({
      lookups,
      loading,
      setLoading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: undefined,
          });
          const lookups = await getLookups();
          setLookup(lookups);
          setLoading({
            loading: false,
            loaded: true,
            error: undefined,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
    }),
    [lookups, loading],
  );

  return <LookupContext.Provider value={value}>{children}</LookupContext.Provider>;
};
