import { endpoint } from '../base';
import { Company } from '../companies';

export type Customer = {
  id: string;
  name: string;
  category: string;
  vatNumber: string;
  company: string | Company;
  currency: string;
  invoiceDate: Date;
  website: string;
  address: string;
  defaultNote: string;
  defaultDiscount: string;
  vatRate: number;
  paymentTerms: number;
  contactName: string;
  contactNumber: string;
  contactEmail: string;
};

export const customers = endpoint<Customer>({
  endpoint: '/admin/customers',
});
