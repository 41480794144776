import React, { forwardRef, useContext, useState, ReactText } from 'react';
import classnames from 'classnames';
import { LoadingWrapper } from '../../shared/Loading';
import { Loader } from 'semantic-ui-react';
import { Form, Button, Segment, Input, Grid, Table as T, Icon } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormField, Dropdown, CreateableDropdown } from '../../shared/Fields';
import { Invoice, exportInvoices, bulkStatusUpdate } from '../../lib/api/invoices';
import { CustomerContext } from '../../contexts/CustomerContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { Section } from '../../shared/Header';
import { PadlessSegment } from '../../shared/Segments';
import { updateInvoice } from '../../lib/api/invoices';
import Select from 'react-select';
import { format, addMonths, setDate, startOfYear, endOfYear } from 'date-fns';
import Fiscal from 'fiscal';

export const invoiceStatuses: String[] = ['All', 'Draft', 'Sent', 'Paid', 'Archived', 'Overdue'];

export interface ReportFormProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  filters: any;
  setFilters: any;
  invoices: Invoice[];
  userCompany: string;
  batchOperationIds: { [id: string]: boolean };
  setBatchOperationIds: any;
  hydrate: (company: string, page?: { number: number; size: number }, where?: { [index: string]: any }) => Promise<void>;
}

type FormState = {
  from_date: string;
  to_date: string;
  customer: { value?: string; label?: string | ReactText };
  statuses: string[];
};

const formatDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

const datesFromSelection = (financialYearStart: number, values: any) => {
  const today = new Date();
  const fiscal = new Fiscal(financialYearStart - 1);
  const dateInfo = fiscal.getFiscalInfoForDate();

  const dates: { [key: string]: () => string[] } = {
    custom: () => [values.from_date, values.to_date],
    currentMonth: () => [formatDate(setDate(today, 1)), formatDate(addMonths(setDate(today, 1), 1))],
    previousMonth: () => [formatDate(addMonths(setDate(today, 1), -1)), formatDate(setDate(today, 0))],
    currentFiscalQuarter: () => [formatDate(dateInfo.quarter.startDate), formatDate(dateInfo.quarter.endDate)],
    previousFiscalQuarter: () => [formatDate(dateInfo.quarter.previous().startDate), formatDate(dateInfo.quarter.previous().endDate)],
    currentFiscalYear: () => {
      const fiscalStart = dateInfo.fiscalYear.startDate;
      return [formatDate(fiscalStart), formatDate(addMonths(fiscalStart, 11))];
    },
    previousFiscalYear: () => {
      const prevStart = dateInfo.fiscalYear.previous().startDate;
      return [formatDate(prevStart), formatDate(addMonths(prevStart, 11))];
    },
    currentYear: () => [formatDate(startOfYear(today)), formatDate(endOfYear(today))],
    previousYear: () => [formatDate(addMonths(startOfYear(today), -12)), formatDate(addMonths(endOfYear(today), -12))],
  };
  return dates;
};

const dateOptions = [
  { label: 'This Month', value: 'currentMonth' },
  { label: 'Last Month', value: 'previousMonth' },
  { label: 'This Fiscal Quarter', value: 'currentFiscalQuarter' },
  { label: 'Last Fiscal Quarter', value: 'previousFiscalQuarter' },
  { label: 'This Fiscal Year', value: 'currentFiscalYear' },
  { label: 'Last Fiscal Year', value: 'previousFiscalYear' },
  { label: 'This Year', value: 'currentYear' },
  { label: 'Last Year', value: 'previousYear' },
  { label: 'Custom', value: 'custom' },
];

export const ReportForm = forwardRef<HTMLDivElement, ReportFormProps>(
  (
    { children, filters, setFilters, invoices, userCompany, batchOperationIds, setBatchOperationIds, hydrate, className, ...props },
    ref,
  ) => {
    const { pageSize, page, customer, from_date, to_date, status, period } = filters;
    const { customers, loading: customerLoading } = useContext(CustomerContext);
    const {
      company: { financialYearStart },
    } = useContext(CompanyContext);

    const [file, setFile] = useState<any>('');
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [batchLoader, setBatchLoader] = useState<boolean>(false);
    const [datePeriod, setDatePeriod] = useState(period || 'currentMonth');

    if (!customerLoading.loaded) {
      return (
        <LoadingWrapper {...props} ref={ref} className={classnames('', {}, className)}>
          <Loader size="medium" active inline>
            Loading Reports...
          </Loader>
        </LoadingWrapper>
      );
    }

    const selectedCustomer = customers.find((cust) => cust.id == customer) || null;
    const showExport = Object.entries(batchOperationIds).length !== 0;

    return (
      <div {...props} ref={ref} className={classnames('', {}, className)}>
        <Section>
          <h3>Reports</h3>
          {showExport && (
            <Grid columns="1">
              <Grid.Row>
                <Grid.Column>
                  <Button.Group size="tiny" floated="right">
                    <Button
                      color="olive"
                      disabled={exportLoading}
                      loading={exportLoading}
                      onClick={async () => {
                        setExportLoading(true);
                        const invToUpdate = invoices.filter((inv: Invoice) => batchOperationIds[inv.id]);
                        const res = await exportInvoices(userCompany, invToUpdate.map((inv) => inv.id));
                        const fileResolved = await res.blob();
                        setFile(fileResolved);
                        setExportLoading(false);
                      }}
                    >
                      <Icon name="download" />
                      Export
                    </Button>
                    <Button
                      type="button"
                      disabled={batchLoader}
                      onClick={async () => {
                        setBatchLoader(true);
                        const invToUpdate = invoices.filter((inv: Invoice) => batchOperationIds[inv.id]);
                        bulkStatusUpdate(userCompany, invToUpdate.map((i) => i.id),
                        "Paid").then((data) => {
                          hydrate(
                            userCompany,
                            { size: pageSize || 50, number: (page || 1) - 1 },
                            {
                              customer: customer,
                              due_from: from_date,
                              due_to: to_date,
                              status: status,
                            },
                          );
                          setBatchLoader(false);
                        });
                      }}
                      loading={batchLoader}
                      color={'olive'}
                    >
                      <Icon name="credit card" />
                      Mark Paid
                    </Button>
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Section>
        <FinalForm<Partial<FormState>>
          initialValues={{
            from_date: from_date || '',
            to_date: to_date || '',
            customer: selectedCustomer ? { value: selectedCustomer.id, label: selectedCustomer.name } : { value: 'all', label: 'All' },
            statuses: (status || ['All']).map((status: string) => ({ label: status, value: status })),
          }}
          onSubmit={(values) => {
            const [startDate, endDate] = datesFromSelection(financialYearStart, values)[datePeriod]();
            const cust = (values.customer as any).value;
            setFilters({
              customer: cust == 'All' ? undefined : cust,
              from_date: startDate,
              to_date: endDate,
              status: (values.statuses || []).map((status) => {
                return (status as any).value;
              }),
              period: datePeriod,
            });
          }}
          render={({ handleSubmit, submitting, values: v }) => {
            return (
              <Form size="large" className="report-filter-selection" onSubmit={handleSubmit} keepDirtyOnReinitialize>
                {showExport && (
                  <>
                    {file && (
                      <a
                        ref={(a) => {
                          if (a) {
                            a.click();
                          }
                          setFile('');
                        }}
                        target="_blank"
                        href={window.URL.createObjectURL(file)}
                        download={`Report-${new Date().toLocaleTimeString()}.zip`}
                        style={{ display: 'none' }}
                      />
                    )}
                  </>
                )}
                <>
                  <PadlessSegment color="olive">
                    <Segment>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width="16">
                            <strong>Period</strong>
                            <Select
                              value={dateOptions.find((el) => el.value == datePeriod) || { label: 'This Month', value: 'currentMonth' }}
                              onChange={(value: any) => setDatePeriod(value.value)}
                              options={dateOptions.map((period) => ({ label: period.label, value: period.value }))}
                            />
                            <br />
                          </Grid.Column>
                          <Grid.Column width="8">
                            {datePeriod == 'custom' && (
                              <Field
                                disabled={false}
                                name="from_date"
                                label="Invoice Due from Date"
                                validate={(value) => !value && 'You need to set a from date'}
                                control={Input}
                                type="date"
                                component={FormField}
                              />
                            )}
                            <Field
                              disabled={false}
                              name="customer"
                              label="Customer"
                              control={Dropdown}
                              validate={(value: any) => !value.value && 'You need to select a customer'}
                              options={[
                                ...[{ label: 'All', value: 'All' }],
                                ...customers.map((customer) => ({ label: customer.name, value: customer.id })),
                              ]}
                              component={FormField}
                            />
                          </Grid.Column>

                          <Grid.Column width="8">
                            {datePeriod == 'custom' && (
                              <Field
                                disabled={false}
                                name="to_date"
                                label="Invoice Due to Date"
                                validate={(value) => !value && 'You need to set a to date'}
                                control={Input}
                                type="date"
                                component={FormField}
                              />
                            )}
                            <Field
                              label="Invoice Status"
                              isMulti
                              disabled={false}
                              name="statuses"
                              control={CreateableDropdown}
                              validate={(value: any) => {
                                if ((value || []).length < 1) {
                                  return 'You need to select atleast one status';
                                }

                                if (value.map((v: any) => v.value).includes('All') && value.length > 1) {
                                  return '"All" filter cannot be combined with any other filter';
                                }
                              }}
                              options={(invoiceStatuses as any).map((status: string) => ({ label: status, value: status }))}
                              component={FormField}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Group size="mini" floated="right">
                          <Button color="olive" type="submit" disabled={submitting} loading={submitting} size="large">
                            Submit
                          </Button>
                        </Button.Group>
                      </div>
                    </Segment>
                  </PadlessSegment>
                </>
              </Form>
            );
          }}
        />
      </div>
    );
  },
);
