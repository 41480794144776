import React, { FC, useMemo } from 'react';
import { Query } from '../lib/api/base';
import styled from 'styled-components';
import { Pagination, Dropdown } from 'semantic-ui-react';

export const PagerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
`;

export const Paginater = styled(Pagination)`
  &&&&& {
    margin-right: 1em;
  }
`;

export const Pager: FC<{
  page: {
    number: number;
    size: number;
    total: number;
  };
  onChange(params: { number: number; size: number }): void;
}> = ({ page, onChange }) => {
  const pages = useMemo(() => {
    return page.total / (page.size || 1);
  }, [page]);
  return (
    <PagerContainer>
      <div>
        {pages > 1 && (
          <Paginater
            size="mini"
            totalPages={pages}
            activePage={page.number}
            prevItem={false}
            nextItem={false}
            onPageChange={(e: any, { activePage }: any) => {
              onChange({
                number: activePage,
                size: page.size,
              });
            }}
          />
        )}
        Show
        <Dropdown
          inline
          value={page.size}
          style={{ marginLeft: '0.5em', marginRight: '0.25em' }}
          onChange={(e: any, a: any) => {
            onChange({
              number: 1,
              size: a.value,
            });
          }}
          options={[
            { text: '2', value: 2 },
            { text: '10', value: 10 },
            { text: '25', value: 25 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
            { text: '150', value: 150 },
          ]}
        />
        results per page of {page.total} in total
      </div>
    </PagerContainer>
  );
};
