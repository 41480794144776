import React, { forwardRef, useContext, useState, FC, ReactText, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import {
  Segment,
  Form,
  Grid,
  Input,
  TextArea,
  Button,
  Table,
  TableCell,
  Message,
  Icon,
  Loader,
  Tab,
  Label,
  SemanticCOLORS,
} from 'semantic-ui-react';
import { Field, Form as FinalForm, FormSpy, FieldRenderProps } from 'react-final-form';
import { FormField, Dropdown, CashInput, PercentageInput, ControlledCheckbox, CreateableDropdown } from '../../shared/Fields';
import { Customer, create as createCustomer, update as updateCustomer, people } from '../../lib/api/customers';
import { CustomerContext } from '../../contexts/CustomerContext';
import { UserContext } from '../../contexts/UserContext';
import { company } from '../../lib/api/companies';
import { LookupContext } from '../../contexts/LookupsContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { extractValidationError } from '../../lib/utils';
import { history } from '../../lib/history';
import { match, Route, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { CustomerPeopleContext } from '../../contexts/CustomerPeopleContext';
import { EnsureCustomerPeople } from '../../fetchers/EnsureCustomerPeople';
import { InvoiceContext } from '../../contexts/InvoiceContext';
import { PadlessSegment } from '../../shared/Segments';
import BigNumber from 'bignumber.js';
import { LoadingWrapper } from '../../shared/Loading';
import {
  addInvoice,
  InvoiceItem,
  updateInvoice,
  removeInvoice,
  Statuses,
  invoiceColors,
  InvoiceAttachment,
  Invoice,
  invoice as getInvoice,
} from '../../lib/api/invoices';
import { mustBePositive } from '../../lib/validation';
import { validate } from '@babel/types';
import { currencies } from '../../lib/currencies';
import { parse, format, addDays } from 'date-fns';
import styled, { css } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { Person } from '../../lib/api/people';
export interface InvoiceFormProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  editing?: boolean;
  match: match<{ id: string; extra: string }>;
}

const BigNum = (value?: string) => new BigNumber(+(value || 0));

const Item = (item: any, vatRate: number) => {
  const vat = BigNum((vatRate || 0) + '');
  const amount = BigNum(item.amount);
  const quantity = BigNum(item.quantity);
  const totalExVat = amount.times(quantity);
  return {
    ...item,
    amount,
    quantity,
    totalExVat,
    total: totalExVat.times(item.vatable ? vat.plus(1) : 1),
    totalVat: amount.times(quantity).times(item.vatable ? vat : 0),
  };
};

export const Status: FC<{ status: Statuses }> = ({ status }) => {
  return (
    <Label ribbon size="medium" color={invoiceColors[status] || 'black'}>
      {status}
    </Label>
  );
};

type FormState = {
  id: string;
  sent: Date;
  due: string;
  issued: string;
  number: string;
  reference: string;
  customerReference: string;
  notes: string;
  amount: number;
  customerDisplayName?: string;
  total?: number;
  customer: { value?: string; label?: string | ReactText };
  vatRate: number;
  recurs: { value?: string; label?: string | ReactText };
  items: { [id: string]: Partial<InvoiceItem> };
  status: Statuses;
  newStatus?: Statuses;
  mail?: boolean;
  mailTo: { value?: string; label?: string | ReactText }[];
  attachmentsToUpload: FileUpload[];
  attachments: InvoiceAttachment[];
};

const VatCol = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.5em;
  && {
    .field {
      margin: 0;
    }
  }
`;

const SendConfirmation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  float: left;
`;

const SendConfirmationMessage = styled.p`
  padding-right: 10px;
  color: #555;
  margin: 0 auto;
`;

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export type FileUpload = File & { id: string };

export const AttachmentUpload: FC<{
  onDrop(acceptedFiles: File[]): void;
  files: FileUpload[];
  removeExisting: (attachment: InvoiceAttachment) => void;
  removeFile: (file: FileUpload) => void;
  invoice: string;
  company: string;
  existingFiles: InvoiceAttachment[];
}> = ({ onDrop, invoice, company, files, existingFiles, removeFile, removeExisting, ...props }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <input style={{ display: 'none' }} {...getInputProps()} />
      <Button type="button" icon {...(getRootProps() as any)}>
        <Icon name="paperclip" />
      </Button>
      {files.map((file, i) => (
        <Label key={i}>
          {file.name}
          <Icon name="delete" onClick={() => removeFile(file)} />
        </Label>
      ))}
      {(existingFiles || []).map((file, i) => (
        <Label as="a" href={`/api/companies/${company}/invoices/${invoice}/attachments/${file.id}`} color="blue" key={i}>
          {file.meta.originalname}
          <Icon name="delete" onClick={() => removeExisting(file)} />
        </Label>
      ))}
    </div>
  );
};

export const InvoiceForm = forwardRef<HTMLDivElement, InvoiceFormProps>(({ match, children, editing, className, ...props }, ref) => {
  const { user, loading: userLoading } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const { hydrate } = useContext(InvoiceContext);
  const { customers, loading: customerLoading, hydrate: hydrateCustomers } = useContext(CustomerContext);
  const { lookups, loading: lookupsLoading } = useContext(LookupContext);
  const { invoices, loading } = useContext(InvoiceContext);
  const prevInvoiceId = usePrevious(match.params.id);

  const [invoiceLoading, setInvoiceLoading] = useState({
    loading: false,
    loaded: false,
  });
  const [invoice, setInvoice] = useState<Invoice>({} as any);
  const customer = customers.find((customer) => (invoice || { customer: '' }).customer == customer.id) || {
    id: '',
    customerEmail: '',
    name: '',
  };
  const [customerPeople, setCustomerPeople] = useState<Person[]>([]);

  useEffect(() => {
    if (customer.id) {
      people(user.company, customer.id).then((people) => setCustomerPeople(people));
    }
  }, [customer]);

  const reloadInvoice = useCallback(() => {
    setInvoiceLoading({
      loading: true,
      loaded: false,
    });
    return getInvoice(user.company, match.params.id).then((invoice) => {
      setInvoice(invoice);
      setInvoiceLoading({
        loading: false,
        loaded: true,
      });
    });
  }, [setInvoiceLoading, setInvoice, user, match]);

  useEffect(() => {
    if (
      user.company &&
      (match.params.id != prevInvoiceId || (editing && !invoiceLoading.loaded && !invoiceLoading.loading && userLoading.loaded))
    ) {
      reloadInvoice();
    }
  }, [editing, invoiceLoading, setInvoice, setInvoiceLoading, userLoading, match.params.id, user.company]);

  if ((editing && !invoiceLoading.loaded) || !loading.loaded || loading.loading || !lookupsLoading.loaded || !customerLoading.loaded) {
    return (
      <LoadingWrapper {...props} ref={ref} className={classnames('', {}, className)}>
        <Loader size="medium" active inline>
          Preparing your invoice...
        </Loader>
      </LoadingWrapper>
    );
  }

  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <FinalForm<Partial<FormState>>
        initialValues={
          editing
            ? {
                ...invoice,
                status: invoice.status as Statuses,
                customer: {
                  value: invoice.customer,
                  label: customer.name,
                },
                due: format(invoice.due as Date, 'yyyy-MM-dd'),
                issued: format(invoice.issued as Date, 'yyyy-MM-dd'),
                recurs: { value: invoice.recurs, label: invoice.recurs },
                mailTo: [{ value: customer.customerEmail, label: customer.customerEmail }],
                items: (invoice.items || []).reduce((items: { [id: string]: any }, item) => {
                  items[item.id] = item;
                  return items;
                }, {}),
                attachmentsToUpload: [],
                attachments: invoice.attachments || [],
                mail: false,
              }
            : {
                customer: { value: '', label: '' },
                recurs: { value: '', label: '' },
                mailTo: [],
                items: {
                  '0-item': {
                    id: '0-item',
                    quantity: 1,
                    vatable: true,
                  },
                },
                issued: format(new Date(), 'yyyy-MM-dd'),
                due: format(new Date(), 'yyyy-MM-dd'),
                vatRate: 0,
                status: 'Draft',
                attachmentsToUpload: [],
                attachments: [],
                mail: false,
              }
        }
        onSubmit={async (v) => {
          const values = v as FormState;
          try {
            if (Object.keys(values.items).length < 1) {
              return {
                items: 'You need at least one item for this invoice',
              };
            }

            const [response, result] = await (editing
              ? updateInvoice(user.company, values.id, {
                  ...values,
                  customer: values.customer.value,
                  recurs: values.recurs.value,
                  items: Object.values(values.items) as InvoiceItem[],
                  status: values.newStatus || values.status,
                  due: values.due,
                  issued: values.issued,
                  mailTo: values.mailTo.map((v) => v.value || ''),
                  currency: (customers.find((customer) => customer.id == values.customer.value) || { currency: 'USD' }).currency,
                })
              : addInvoice(user.company, {
                  ...values,
                  customer: values.customer.value,
                  recurs: values.recurs.value,
                  items: Object.values(values.items) as InvoiceItem[],
                  status: values.newStatus || values.status,
                  due: values.due,
                  issued: values.issued,
                  mailTo: values.mailTo.map((v) => v.value || ''),
                  currency: (customers.find((customer) => customer.id == values.customer.value) || { currency: 'USD' }).currency,
                }));

            if (response.status == 422) {
              const validation = await response.json();
              return extractValidationError(validation);
            }

            if (response.status != 200) {
              throw new Error('Unable to save');
            }

            await Promise.all([hydrate(company.id), hydrateCustomers(company.id), editing ? reloadInvoice() : Promise.resolve()]);

            if (values.newStatus == 'Archived') {
              return history.push(`/invoices`);
            }

            return history.push(`/invoices/${editing ? match.params.id : result.data.id}/edit/preview`);
          } catch (e) {
            return {
              message: 'Failed to save this invoice',
            };
          }
        }}
        render={({ handleSubmit, values: v, invalid, submitting, dirty, form, submitErrors }) => {
          const values = v as FormState;
          const [toRemoveInvoice, setRemoveInvoice] = useState(false);
          const items = Object.values(values.items).map((item: any) => Item(item, values.vatRate));
          const disabled = submitting;
          const customer = customers.find((customer) => customer.id == values.customer.value) || {
            name: '',
            currency: 'USD',
            customerEmail: '',
            people: [],
          };
          const [confirmSend, setConfirmSend] = useState(false);
          useEffect(() => {
            if (!editing) {
              const customer = customers.find((customer) => customer.id == values.customer.value) || {
                vatRate: values.vatRate,
                paymentTerms: 0,
                defaultNote: '',
                customerEmail: '',
              };
              form.batch(() => {
                form.change('vatRate', customer.vatRate);
                form.change('due', format(addDays(new Date(), customer.paymentTerms), 'yyyy-MM-dd'));
                form.change('notes', customer.defaultNote);
                form.change('mailTo', [{ value: customer.customerEmail, label: customer.customerEmail }]);
              });
            }
          }, [values.customer.value]);
          return (
            <>
              <h3>
                {editing
                  ? `Invoice - ${values.number} - ${customer.name} - ${
                      values.status == 'Draft'
                        ? (values.issued ? parse(values.issued, 'yyyy-MM-dd', new Date()) : new Date()).toLocaleDateString()
                        : (values.sent || new Date()).toLocaleDateString()
                    }`
                  : 'Create invoice'}
              </h3>
              <Form onSubmit={handleSubmit} disabled={disabled}>
                <Grid columns="1">
                  <Grid.Row>
                    <Grid.Column>
                      {confirmSend ? (
                        <SendConfirmation>
                          <SendConfirmationMessage>
                            <Field
                              label="Send this invoice to"
                              isMulti
                              disabled={disabled}
                              name="mailTo"
                              control={CreateableDropdown}
                              validate={(value: any) => (value || []).length < 1 && 'You need to select a person to mail to'}
                              options={customerPeople.map((person) => ({ value: person.email, label: person.email }))}
                              component={FormField}
                            />
                          </SendConfirmationMessage>
                          <Button.Group size="tiny" floated="right">
                            <Button
                              type="button"
                              disabled={!editing}
                              onClick={() => {
                                setConfirmSend(false);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              color={invoiceColors[values.status]}
                              disabled={!editing}
                              loading={submitting}
                              onClick={(e) => {
                                e.preventDefault();
                                form.batch(() => {
                                  form.change('mail', true);
                                  if (values.status == 'Draft') {
                                    return form.change('newStatus', 'Sent');
                                  }
                                });
                                form.submit();
                              }}
                            >
                              Confirm
                            </Button>
                          </Button.Group>
                        </SendConfirmation>
                      ) : (
                        <Button.Group size="tiny" floated="left">
                          {!['Paid', 'Archived'].includes(values.status) && match.params.extra != 'preview' && (
                            <Button disabled={disabled} type="submit" loading={submitting} color={invoiceColors[values.status]}>
                              Save
                            </Button>
                          )}
                          {editing && !['Paid', 'Archived'].includes(values.status) && match.params.extra == 'preview' && (
                            <Button as={Link} to={`/invoices/${values.id}/edit`} disabled={!editing} color={invoiceColors[values.status]}>
                              <Icon name="pencil" />
                              Edit
                            </Button>
                          )}
                          {editing && values.status == 'Draft' && (
                            <>
                              <Button
                                type="button"
                                disabled={!editing}
                                onClick={() => {
                                  setConfirmSend(true);
                                }}
                                loading={submitting}
                                color={invoiceColors[values.status]}
                              >
                                <Icon name="mail" />
                                Save and send
                              </Button>
                            </>
                          )}
                          {values.status != 'Draft' && (
                            <Button
                              type="button"
                              disabled={!editing}
                              onClick={() => {
                                setConfirmSend(true);
                              }}
                              color={invoiceColors[values.status]}
                            >
                              <Icon name="mail" />
                              Resend
                            </Button>
                          )}
                          {editing && ['Sent', 'Overdue'].includes(values.status) && (
                            <Button
                              type="button"
                              disabled={!editing}
                              onClick={(e) => {
                                e.preventDefault();
                                form.change('newStatus', 'Paid');
                                form.submit();
                              }}
                              loading={submitting}
                              color={invoiceColors[values.status]}
                            >
                              <Icon name="credit card" />
                              Mark Paid
                            </Button>
                          )}
                          {editing && ['Paid'].includes(values.status) && (
                            <Button
                              type="button"
                              disabled={!editing}
                              onClick={(e) => {
                                e.preventDefault();
                                form.change('newStatus', 'Sent');
                                form.submit();
                              }}
                              loading={submitting}
                              color={invoiceColors[values.status]}
                            >
                              <Icon name="close" />
                              Mark Unpaid
                            </Button>
                          )}
                          <Button as={Link} to="/invoices">
                            Back
                          </Button>
                        </Button.Group>
                      )}
                      <Button.Group size="tiny" floated="right">
                        <Button
                          type="button"
                          disabled={!editing}
                          icon
                          color={invoiceColors[values.status]}
                          onClick={async () => {
                            const [response, result] = await addInvoice(user.company, {
                              ...values,
                              number: undefined,
                              customer: values.customer.value,
                              vatRate: values.vatRate,
                              recurs: values.recurs.value,
                              items: Object.values(values.items) as InvoiceItem[],
                              due: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
                              issued: format(new Date(), 'yyyy-MM-dd'),
                              mailTo: [],
                              status: 'Draft',
                              sent: undefined,
                            });
                            await hydrate(user.company, { number: 0, size: 50 });
                            return history.push(`/invoices/${result.data.id}/edit`);
                          }}
                        >
                          <Icon name="copy outline" />
                          Duplicate
                        </Button>
                        {values.status != 'Archived' && (
                          <Button
                            type="button"
                            disabled={!editing}
                            onClick={() => {
                              form.change('newStatus', 'Archived');
                              form.submit();
                            }}
                            loading={submitting}
                            color={invoiceColors[values.status]}
                          >
                            <Icon name="file" />
                            Archive
                          </Button>
                        )}
                        {editing && values.status == 'Draft' && (
                          <>
                            <Button
                              type="button"
                              disabled={!editing}
                              onClick={() => {
                                form.batch(() => {
                                  form.change('newStatus', 'Sent');
                                  form.change('mail', false);
                                });
                                form.submit();
                              }}
                              loading={submitting}
                              color={invoiceColors[values.status]}
                            >
                              <Icon name="credit card" />
                              Mark Sent
                            </Button>
                          </>
                        )}
                        {editing && match.params.extra != 'preview' && (
                          <Button
                            as={Link}
                            to={`/invoices/${values.id}/edit/preview`}
                            disabled={!editing}
                            color={invoiceColors[values.status]}
                          >
                            <Icon name="globe" />
                            {values.status == 'Draft' ? 'Preview' : 'View online'}
                          </Button>
                        )}

                        <Button
                          as="a"
                          href={`/api/companies/${user.company}/invoice/${values.id}/pdf`}
                          download
                          disabled={!editing}
                          color={invoiceColors[values.status]}
                        >
                          <Icon name="download" />
                          Download
                        </Button>
                        {toRemoveInvoice && (
                          <>
                            <Button
                              type="button"
                              disabled={!editing}
                              onClick={async () => {
                                await removeInvoice(user.company, values.id);
                                await hydrate(user.company, { number: 0, size: 50 });
                                setRemoveInvoice(false);
                                history.push('/invoices');
                              }}
                              color="pink"
                            >
                              Confirm
                            </Button>
                            <Button
                              type="button"
                              disabled={!editing}
                              onClick={async () => {
                                setRemoveInvoice(false);
                              }}
                              color={invoiceColors[values.status]}
                            >
                              Cancel
                            </Button>
                          </>
                        )}
                        {!toRemoveInvoice && values.status == 'Draft' && (
                          <Button
                            type="button"
                            disabled={disabled}
                            onClick={async () => {
                              setRemoveInvoice(true);
                            }}
                            color={invoiceColors[values.status]}
                          >
                            <Icon name="trash" />
                            Remove
                          </Button>
                        )}
                      </Button.Group>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div>
                  <Grid columns="1">
                    {submitErrors && submitErrors.message && (
                      <Grid.Row>
                        <Grid.Column>
                          <Message negative>{submitErrors.message}</Message>
                        </Grid.Column>
                      </Grid.Row>
                    )}
                    {match.params.extra == 'preview' && (
                      <Grid.Row>
                        <Grid.Column>
                          <Segment color={invoiceColors[values.status]}>
                            <Status status={values.status as Statuses} />
                            <iframe
                              style={{ border: 'none', width: '100%', minHeight: '80vh' }}
                              src={`/api/companies/${user.company}/invoice/${values.id}/preview`}
                            />
                          </Segment>
                          {values.attachments.length > 0 && (
                            <>
                              <h4>Attachments</h4>
                              {(values.attachments || []).map((file, i) => (
                                <Label
                                  as="a"
                                  href={`/api/companies/${company.id}/invoices/${invoice.id}/attachments/${file.id}`}
                                  color="blue"
                                  key={i}
                                >
                                  {file.meta.originalname}
                                </Label>
                              ))}
                            </>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    )}
                    {!match.params.extra && (
                      <Grid.Row>
                        <Grid.Column>
                          <Segment color={invoiceColors[values.status]}>
                            <Status status={values.status as Statuses} />
                            <div style={{ margin: '0.5em' }} />
                            <Grid>
                              <Grid.Row>
                                <Grid.Column width="8">
                                  <Form.Group grouped>
                                    <Field
                                      disabled={disabled}
                                      name="reference"
                                      label="Reference/PO Number"
                                      placeholder="e.g. Monthly consulting for Compco"
                                      control={Input}
                                      component={FormField}
                                    />
                                    <Field
                                      disabled={disabled}
                                      name="due"
                                      label="Due by"
                                      validate={(value) => !value && 'You need to set a due date'}
                                      control={Input}
                                      type="date"
                                      component={FormField}
                                    />
                                    <Field
                                      disabled={disabled}
                                      name="issued"
                                      label="Issue date"
                                      validate={(value) => !value && 'You need to set a issue date'}
                                      control={Input}
                                      type="date"
                                      component={FormField}
                                    />
                                  </Form.Group>
                                </Grid.Column>
                                <Grid.Column width="8">
                                  <Form.Group grouped>
                                    <Field
                                      disabled={disabled}
                                      name="customer"
                                      label="Customer"
                                      control={Dropdown}
                                      validate={(value: any) => !value.value && 'You need to select a customer'}
                                      options={customers.map((customer) => ({ label: customer.name, value: customer.id }))}
                                      component={FormField}
                                    />
                                    <Field
                                      disabled={disabled}
                                      name="customerReference"
                                      label="Subject"
                                      validate={(value) => !value && 'You need to set a customer refrece'}
                                      placeholder="e.g. Monthly consulting"
                                      control={Input}
                                      component={FormField}
                                    />
                                    <Field
                                      disabled={disabled}
                                      name="vatRate"
                                      label="Tax Rate"
                                      control={PercentageInput}
                                      component={FormField}
                                    />
                                  </Form.Group>
                                </Grid.Column>
                                <Grid.Column width="16">
                                  <Form.Group grouped>
                                    <Field
                                      disabled={disabled}
                                      name="notes"
                                      label="Notes"
                                      placeholder="e.g. Previous invoice is overdue"
                                      control={TextArea}
                                      rows={4}
                                      component={FormField}
                                    />
                                  </Form.Group>
                                </Grid.Column>
                                <Grid.Column width="16">
                                  <b>Attachments</b>
                                  <AttachmentUpload
                                    invoice={values.id}
                                    company={company.id}
                                    onDrop={(files: FileUpload[]) => {
                                      form.change('attachmentsToUpload', [
                                        ...values.attachmentsToUpload,
                                        ...files.map((file, i) =>
                                          Object.assign(file, {
                                            id: values.attachmentsToUpload.length + i,
                                          }),
                                        ),
                                      ]);
                                    }}
                                    removeFile={(attachment) => {
                                      form.change(
                                        'attachmentsToUpload',
                                        values.attachmentsToUpload.filter((a) => a.id != attachment.id),
                                      );
                                    }}
                                    removeExisting={(attachment) => {
                                      form.change(
                                        'attachments',
                                        values.attachments.filter((a) => a.id != attachment.id),
                                      );
                                    }}
                                    existingFiles={values.attachments}
                                    files={values.attachmentsToUpload}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </Grid>
                  {!match.params.extra && (
                    <Grid columns="1">
                      <Grid.Row>
                        <Grid.Column>
                          <h3>Items</h3>
                          {submitErrors && submitErrors.items && <Message negative>{submitErrors.items}</Message>}
                          <PadlessSegment color={invoiceColors[values.status]}>
                            <div onSubmit={(e: any) => e.preventDefault()}>
                              <Table fixed compact>
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell>Description</Table.HeaderCell>
                                    <Table.HeaderCell width="two">Qty</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right" width="three">
                                      Unit Price {values.vatRate != 0 && '(excl. Tax)'}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right" width="three">
                                      Amount
                                    </Table.HeaderCell>
                                    {values.vatRate != 0 && (
                                      <Table.HeaderCell width="one" textAlign="right">
                                        Tax
                                      </Table.HeaderCell>
                                    )}
                                    <Table.HeaderCell width="two">
                                      <Button.Group floated="right" size="mini">
                                        <Button
                                          color={invoiceColors[values.status]}
                                          icon
                                          disabled={disabled}
                                          labelPosition="right"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            form.change('items', {
                                              ...values.items,
                                              [`${items.length}-item`]: { id: `${items.length}-item`, quantity: 1, vatable: true },
                                            });
                                          }}
                                        >
                                          <Icon name="plus circle" />
                                          Add
                                        </Button>
                                      </Button.Group>
                                    </Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {items.map((item: any) => (
                                    <Table.Row key={item.id}>
                                      <Table.Cell>
                                        <Field
                                          hideError
                                          disabled={disabled}
                                          name={`items[${item.id}].description`}
                                          placeholder="e.g. Services Rendered"
                                          control={Input}
                                          validate={(value) => !value && 'You need to provide a description'}
                                          component={FormField}
                                        />
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        <Field
                                          hideError
                                          disabled={disabled}
                                          name={`items[${item.id}].quantity`}
                                          placeholder="e.g. 1"
                                          validate={(value) => {
                                            if (value == 0) {
                                              return undefined;
                                            }
                                            return !value && 'You need to provide a quantity';
                                          }}
                                          control={Input}
                                          type="number"
                                          component={FormField}
                                        />
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        <Field
                                          hideError
                                          disabled={disabled}
                                          name={`items[${item.id}].amount`}
                                          placeholder="e.g. 100"
                                          control={Input}
                                          validate={(value) => {
                                            if (value == 0) {
                                              return undefined;
                                            }
                                            return !value && 'You need to provide an amount';
                                          }}
                                          type="number"
                                          component={FormField}
                                        />
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        <span style={{ paddingRight: '0.7em' }}>
                                          {currencies[customer.currency].symbol}{' '}
                                          {new BigNumber((values as any).items[item.id].amount || 0)
                                            .times((values as any).items[item.id].quantity || 0)
                                            .toFormat(2)}
                                        </span>
                                      </Table.Cell>
                                      {values.vatRate != 0 && (
                                        <Table.Cell textAlign="right">
                                          <Field
                                            hideError
                                            style={{ width: 25, float: 'right' }}
                                            disabled={disabled}
                                            name={`items[${item.id}].vatable`}
                                            type="checkbox"
                                            control={ControlledCheckbox}
                                            component={FormField}
                                          />
                                        </Table.Cell>
                                      )}
                                      <Table.Cell>
                                        <Button
                                          size="mini"
                                          icon
                                          disabled={disabled}
                                          floated="right"
                                          labelPosition="right"
                                          type="button"
                                          onClick={(e) => {
                                            // Values can go out of scope so we make a copy
                                            const vs = { ...values.items };
                                            form.change(
                                              'items',
                                              Object.keys(vs).reduce((values: any, id: any) => {
                                                if (id != item.id) {
                                                  values[id] = vs[id];
                                                }
                                                return values;
                                              }, {}),
                                            );
                                          }}
                                        >
                                          <Icon name="trash" />
                                          Remove
                                        </Button>
                                      </Table.Cell>
                                    </Table.Row>
                                  ))}
                                </Table.Body>
                                <Table.Footer>
                                  <Table.Row>
                                    <Table.HeaderCell textAlign="right" colSpan={3}>
                                      Total {values.vatRate != 0 && ' (excl. Tax)'}
                                      {values.vatRate != 0 && (
                                        <>
                                          <br />
                                          Tax
                                          <br />
                                          <b>Total</b>
                                        </>
                                      )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right">
                                      {currencies[customer.currency].symbol}{' '}
                                      {items
                                        .reduce((total: BigNumber, item: any) => total.plus(item.totalExVat), new BigNumber(0))
                                        .toFormat(2)}
                                      {values.vatRate != 0 && (
                                        <>
                                          <br />
                                          {currencies[customer.currency].symbol}{' '}
                                          {items
                                            .reduce((total: BigNumber, item: any) => total.plus(item.totalVat), new BigNumber(0))
                                            .toFormat(2)}
                                          <br />
                                          <b>
                                            {currencies[customer.currency].symbol}{' '}
                                            {items
                                              .reduce((total: BigNumber, item: any) => total.plus(item.total), new BigNumber(0))
                                              .toFormat(2)}
                                          </b>
                                        </>
                                      )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell colSpan={values.vatRate != 0 ? 2 : 1}></Table.HeaderCell>
                                  </Table.Row>
                                </Table.Footer>
                              </Table>
                            </div>
                          </PadlessSegment>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                </div>
              </Form>
            </>
          );
        }}
      />
    </div>
  );
});
