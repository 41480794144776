import React, { forwardRef, useContext, useState } from 'react';
import classnames from 'classnames';
import { match } from 'react-router';
import { Segment } from '../../../shared/Segments';
import styled, { css } from 'styled-components';
import { profileColors } from '../shared';
import { Label, Button, Icon, List, Loader, Message } from 'semantic-ui-react';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { subscribe } from '../../../lib/api/companies';
import { UserContext } from '../../../contexts/UserContext';
import { Notice } from '../../../shared/Notice';
import { Link } from 'react-router-dom';
import { LoadingWrapper } from '../../../shared/Loading';

export interface SubscriptionProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin-left: 10em;
  margin-right: 10em;
  .ui.segment {
    & {
      margin: 0;
    }
  }
`;

const Plan = styled(Segment)`
  &&& {
    max-width: 250px;
    margin-top: 40px;
    padding-top: 50px;
  }
  position: relative;
  .content {
    text-align: center;
  }
  h3 {
    margin-bottom: 0;
  }
  .listitem {
    .icon {
      padding-right: 0.75em;
    }
  }
  .ui.list {
    margin-top: 2em;
    margin-bottom: 2em;
  }
`;

const Indicator = styled.div<{ bg: string; left?: string }>`
  position: absolute;
  top: 0;
  padding: 0.25em;
  left: ${({ left }) =>
    css`
      ${left || 50}%
    `};
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bg }) =>
    css`
      ${bg}
    `};

  color: #fafafa;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  text-align: center;
  font-weight: bold;
  .icon {
    width: auto;
    height: auto;
    font-size: 3em;
  }

  .icon.play {
    margin-left: 10px;
  }
`;

export const Subscriptions = forwardRef<HTMLDivElement, SubscriptionProps>(({ match, children, className, ...props }, ref) => {
  const { company, hydrate, loading } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const [loadingSubscription, setLoadingSubscription] = useState(false);

  const selected = (plan: string) => {
    if (company.subscription != plan) {
      return {};
    } else
      switch (plan) {
        case 'Booming':
          return { background: 'rgb(246,195,88)', padding: '5px', borderRadius: '5px', color: '#FFFFFF', fontWeight: 600 };
        case 'Starter':
          return { background: 'rgb(59,178,220)', padding: '5px', borderRadius: '5px', color: '#FFFFFF', fontWeight: 600 };
        default:
          break;
      }
  };
  if (!loading.loaded) return <></>;
  else
    return (
      <>
        {/* {loading.loaded && user.companyDetails.subscription == 'Starter' && (user.companyDetails.daysLeftInTrial || 0) <= 0 && (
        <Message info>Your free trail has expired. Please pick from one of the plans below to continue</Message>
      )} */}
        <Container ref={ref} className={classnames('', {}, className)}>
          <Plan raised={company.subscription == 'Starter'}>
            <Indicator bg="rgb(59, 178, 220)">
              <Icon name="play" />
            </Indicator>
            <div className="content">
              <h3>Starter</h3>
              <span>Free</span>
              <hr />
            </div>
            <List>
              <List.Item>
                <List.Icon name="check circle outline" />
                {'Single User'}
              </List.Item>
              <List.Item>
                <List.Icon name="check circle outline" />
                {'2 Customers'}
              </List.Item>
            </List>
            <div className="content" style={{ ...selected('Starter') }}>
              {company.subscription == 'Starter' && <span>This is your current plan</span>}
              {!['Starter', 'Growing', 'Booming', 'Arrived'].includes(company.subscription) && (
                <Button loading={loadingSubscription}>Upgrade</Button>
              )}
            </div>
          </Plan>
          <Plan raised={company.subscription == 'Booming'}>
            <Indicator bg="rgb(246, 195, 88)">
              <Icon name="rocket" />
            </Indicator>
            <div className="content">
              <h3>Annual</h3>
              <span>R795 p/y</span>
              <hr />
            </div>
            <List>
              <List.Item>
                <List.Icon name="check circle outline" />
                Unlimited Users
              </List.Item>

              <List.Item>
                <List.Icon name="check circle outline" />
                Unlimited Invoices
              </List.Item>
            </List>
            <div className="content" style={{ ...selected('Booming') }}>
              {company.subscription == 'Booming' && <span>This is your current plan</span>}
              {!['Booming', 'Arrived'].includes(company.subscription) && company.subscription != 'Booming' && (
                <Button as={Link} to="/profile/subscribe/Booming" color="yellow" loading={loadingSubscription}>
                  Upgrade
                </Button>
              )}
            </div>
          </Plan>
        </Container>
        <br />
        <br />
        <div style={{ maxWidth: '233px', marginLeft: '33%' }}>
          <p style={{ textAlign: 'center' }}>
            If you have any questions, please don't hesitate to contact{' '}
            <a href="mailto:queries@vulcaninvoicing.com">queries@vulcaninvoicing.com</a>, we will get back yto you ASAP.
          </p>
        </div>
      </>
    );
});
