import { Segment, Icon, Button, SegmentProps, Header, SemanticICONS } from 'semantic-ui-react';

import React, { FC, ReactNode } from 'react';

export const Notice: FC<SegmentProps & { message: string; icon?: SemanticICONS | ReactNode }> = ({
  children,
  color,
  icon,
  message,
  ...props
}) => {
  return (
    <Segment placeholder color={color} {...props}>
      <Header icon>
        {icon && typeof icon === 'string' ? <Icon name={icon as SemanticICONS} color={color} /> : icon}
        {message}
      </Header>
      {children}
    </Segment>
  );
};
