import React, { createContext, useState, useMemo, FC, useContext } from 'react';
import { Company as BaseCompany, company as getCompany, company_people, updateCompany, removeCustomer } from '../lib/api/companies';
import { Person } from '../lib/api/people';
import { CustomerContext } from './CustomerContext';

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
  updated_at?: number;
};

export type Company = BaseCompany & { people: Person[] };

const defaultCompany: Company = {
  id: '',
  name: '',
  vatNumber: '',
  website: '',
  paymentMethod: '',
  contactName: '',
  country: '',
  contactEmail: '',
  contactNumber: '',
  bankDetails: '',
  address: '',
  subscription: '',
  financialYearStart: 1,
  people: [],
  logoVersion: '',
  daysLeftInTrial: 0,
  trialOver: false,
  paymentDate: undefined,
};

export type CompanyContextState = {
  company: Company;
  loading: Loading;
  hydrate: (company: string) => Promise<void>;
};

export const CompanyContext = createContext<CompanyContextState>({
  company: defaultCompany,
  loading: { loading: false, loaded: false, error: undefined, updated_at: undefined },
  hydrate: () => Promise.resolve(),
});

export const isCompanySetup = (company: Company) => {
  return !!(company.name && company.bankDetails && company.address && company.vatNumber && (company.logoVersion || company.logo));
};

export const CompanyProvider: FC = ({ children }) => {
  const [company, setCompany] = useState<Company>(defaultCompany);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined, updated_at: undefined });

  const hydrate = async (id: string) => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: undefined,
      });
      const [company, people] = await Promise.all([getCompany(id), company_people(id)]);
      setCompany({
        ...company,
        people,
        complete: isCompanySetup(company as Company),
      });
      setLoading({
        loading: false,
        loaded: true,
        error: undefined,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  };
  const value = useMemo(
    () => ({
      company,
      loading,
      hydrate,
      setCompany,
    }),
    [company, hydrate, loading],
  );

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};
