import React, { forwardRef, useContext } from 'react';
import classnames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Grid, Input, Loader, Image, Segment, TextArea, Card, Feed, Dropdown, Table, Tab } from 'semantic-ui-react';
import { FormField, EmailInput } from '../../../shared/Fields';
import { CompanyContext, CompanyProvider } from '../../../contexts/CompanyContext';
import styled from 'styled-components';
import { Company, updateCompany, invite } from '../../../lib/api/companies';
import { profileColors } from '../shared';
import { extractValidationError } from '../../../lib/utils';
import { CompanyForm } from './Form';
import { CompanyMembers } from './Members';
import { LoadingWrapper } from '../../../shared/Loading';
import { match } from 'react-router';
import { history } from '../../../lib/history';

export interface CompanyProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match<{ route: string }>;
}

const tabs = ['details', 'members'];

export const CompanyDetails = forwardRef<HTMLDivElement, CompanyProps>(({ match, children, className, ...props }, ref) => {
  const { company, loading, hydrate } = useContext(CompanyContext);

  if (!loading.loaded) {
    return (
      <LoadingWrapper {...props} ref={ref} className={classnames('', {}, className)}>
        <Loader size="medium" active inline>
          Loading your company...
        </Loader>
      </LoadingWrapper>
    );
  }

  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <Tab
        activeIndex={match.params.route ? tabs.indexOf(match.params.route) : 0}
        onTabChange={(_, { activeIndex }) =>
          history.push(`/profile/company${tabs[activeIndex as any] ? `/${tabs[activeIndex as any]}` : ``}`)
        }
        panes={[
          {
            menuItem: 'Details',
            render: () => (
              <Tab.Pane>
                <CompanyForm />
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Members',
            render: () => (
              <Tab.Pane>
                <CompanyMembers />
              </Tab.Pane>
            ),
          },
        ]}
      />
    </div>
  );
});
