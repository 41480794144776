import React, { forwardRef, useState, useEffect, useCallback, useContext } from 'react';
import classnames from 'classnames';
import { DashboardData, dashboard as getDashboard, deleteCompany } from '../../lib/api/admin/dashboard';
import { Table, Tab, Statistic, Button } from 'semantic-ui-react';
import { countries } from '../../lib/countries';
import { UserContext } from '../../contexts/UserContext';

export interface DashboardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const useDashboardData = () => {
  const [dashboard, setDashboard] = useState<DashboardData>({ companies: [] });

  const load = useCallback(() => {
    return getDashboard().then((data) => setDashboard(data));
  }, []);

  useEffect(() => {
    load();
  }, []);

  return { dashboard, load };
};

export const Dashboard = forwardRef<HTMLDivElement, DashboardProps>(({ children, className, ...props }, ref) => {
  const { dashboard, load } = useDashboardData();
  const [idToDelete, setIdToDelete] = useState<string>('');
  const [deleting, setDeleting] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  return (
    <div {...props} ref={ref} className={classnames('', {}, className)}>
      <Statistic.Group>
        <Statistic label="Total" value={dashboard.companies.length} />
        <Statistic label="Total Active" value={dashboard.companies.filter((c) => c.invoices > 0).length} />
      </Statistic.Group>
      <Table fixed striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Country</Table.HeaderCell>
            <Table.HeaderCell>Customers</Table.HeaderCell>
            <Table.HeaderCell>Invoices</Table.HeaderCell>
            <Table.HeaderCell>Billing items</Table.HeaderCell>
            <Table.HeaderCell>Contact Email</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dashboard.companies.map((company) => (
            <Table.Row key={company.id}>
              <Table.Cell>{company.name}</Table.Cell>
              <Table.Cell>{(countries.find((c) => c.value == company.country) || { label: 'N/A' }).label}</Table.Cell>
              <Table.Cell>{company.customers}</Table.Cell>
              <Table.Cell>{company.invoices}</Table.Cell>
              <Table.Cell>{company.billingItems}</Table.Cell>
              <Table.Cell>{company.contactEmail}</Table.Cell>
              <Table.Cell>{company.created_at.toLocaleDateString()}</Table.Cell>
              <Table.Cell>
                {(company.billingItems == 0 || user.companies.map((c) => c.id).includes(company.id)) &&
                  (company.id === idToDelete ? (
                    <Button.Group size="tiny">
                      <Button onClick={() => setIdToDelete('')}>Cancel</Button>
                      <Button
                        color="pink"
                        disabled={deleting}
                        loading={deleting}
                        onClick={async () => {
                          setDeleting(true);

                          await deleteCompany(company.id).then(() => {
                            setDeleting(false);
                            setIdToDelete('');
                            load();
                          });
                        }}
                      >
                        Confirm
                      </Button>
                    </Button.Group>
                  ) : (
                    <Button color="blue" size="tiny" onClick={() => setIdToDelete(company.id)}>
                      Delete
                    </Button>
                  ))}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
});
