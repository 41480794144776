import { request } from '../base';

export type DashboardData = {
  companies: {
    id: string;
    name: string;
    country: string;
    created_at: Date;
    invoices: number;
    customers: number;
    contactEmail: string;
    billingItems: number;
  }[];
};

export const dashboard = () =>
  request('/admin/dashboard')
    .then((res) => res.json())
    .then(({ data }) => ({
      ...data,
      companies: data.companies.map((c: any) => ({
        ...c,
        created_at: new Date(c.created_at),
      })),
    }));

export const deleteCompany = (id: string) => request(`/admin/companies/${id}`, { method: 'DELETE' }).then((res) => res.json());
