import React, { forwardRef, useContext } from 'react';
import classnames from 'classnames';
import { Redirect, match } from 'react-router';
import { hydrate } from 'react-dom';
import { Form, Message, Button, Segment, Header } from 'semantic-ui-react';
import { company } from '../../lib/api/companies';
import { Field } from 'react-final-form';
import { EmailInput, PasswordInput, FormField } from '../../shared/Fields';
import { validatePassword } from '../../lib/validation';
import { Link } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import { UserContext } from '../../contexts/UserContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { request } from '../../lib/api/base';
import { DelayedRedirect } from '.';

export interface PasswordResetProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match<{ token: string }>;
}

export const PasswordReset = forwardRef<HTMLDivElement, PasswordResetProps>(({ children, match, className, ...props }, ref) => {
  const { user, hydrate } = useContext(UserContext);
  return (
    <div {...props} ref={ref} className={classnames('', {}, className)}>
      <Header as="h3" color="teal" textAlign="center">
        Reset your password
      </Header>
      <FinalForm<{ password: string; confirmPassword: string }>
        onSubmit={async (values) => {
          try {
            const response = await request('/identity/password-reset', {
              method: 'POST',
              body: JSON.stringify({ ...values, token: match.params.token }),
            });
            const data = await response.json();
            if (response.status == 200) {
              return;
            }
            if (data.message) {
              return data;
            }
            if (response.status !== 200) {
              throw new Error('Failed');
            }
            await hydrate();
          } catch (e) {
            return {
              message: "We weren't able to log you in, please try again later",
            };
          }
        }}
        render={({ handleSubmit, submitting, submitSucceeded, submitErrors }) => {
          return (
            <Form size="large" onSubmit={handleSubmit}>
              {submitErrors && <Message header="Oops! something seems to be broken" content={submitErrors.message} />}
              {submitSucceeded ? (
                <>
                  <Message content={`Awesome, your pasword has been reset`} />
                  <DelayedRedirect to="/login" wait={400} />
                </>
              ) : (
                <Segment>
                  <Field name="password" placeholder="Password" validate={validatePassword} control={PasswordInput} component={FormField} />
                  <Field
                    name="confirmPassword"
                    placeholder="Confirm password"
                    validate={(value: any = '', values: any) => {
                      if (value != values.password) {
                        return 'Your passwords do not match';
                      }
                      return validatePassword(value);
                    }}
                    control={PasswordInput}
                    component={FormField}
                  />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color="teal" type="submit" disabled={submitting} loading={submitting} size="large">
                      Reset password
                    </Button>
                  </div>
                </Segment>
              )}
            </Form>
          );
        }}
      />
      <Message>
        Looking for login? <Link to="/login">Here you go</Link>
      </Message>
    </div>
  );
});
