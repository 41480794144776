import { request } from './base';
import { SemanticCOLORS } from 'semantic-ui-react';
import queryString from 'query-string';

export type InvoiceItem = {
  id: string;
  invoice: string;
  type: string;
  description: string;
  quantity: number;
  vatable: boolean;
  amount: number;
  discount: number;
};

export type Invoice = {
  id: string;
  issued: Date | string;
  due: Date | string;
  sent?: Date;
  number: string;
  reference: string;
  customerReference: string;
  currency: string;
  notes: string;
  status: Statuses;
  amount: number;
  vatRate: number;
  customer: string;
  customerDisplayName?: string;
  recurs: string;
  items?: InvoiceItem[];
  total?: number;
  totalVat?: number;
  mailTo?: string[];
  attachmentsToUpload?: File[];
  attachments: InvoiceAttachment[];
};

export type InvoiceAttachment = {
  id: string;
  invoice: string;
  location: string;
  meta: {
    fieldname: string;
    originalname: string;
    encoding: string;
    mimetype: string;
    size: number;
  };
};

export type Statuses = 'Draft' | 'Sent' | 'Paid' | 'Overdue' | 'Archived';

export const invoiceColors: { [index in Statuses]: SemanticCOLORS } = {
  Draft: 'orange',
  Sent: 'teal',
  Paid: 'green',
  Archived: 'grey',
  Overdue: 'pink',
};

export const invoices = (company: string, page: { number: number; size: number }, where: { [index: string]: any }) =>
  request(
    `/companies/${company}/invoices?page[number]=${page.number}&page[size]=${page.size}&${Object.entries(where)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `where[${key}][]=${v}`).join('&');
        }
        return !!value ? `where[${key}]=${value}` : ``;
      })
      .join('&')}`,
  );

export const invoice = (company: string, id: string) =>
  request(`/companies/${company}/invoices/${id}`)
    .then((res) => res.json())
    .then(({ data }) => ({
      ...data,
      issued: new Date(data.issued),
      sent: new Date(data.sent),
      due: new Date(data.due),
    }));

export const invoiceItems = (company: string, invoice: string) => request(`/companies/${company}/invoices/${invoice}/items`);
export const invoiceAttachments = (company: string, invoice: string) => request(`/companies/${company}/invoices/${invoice}/attachments`);

export const exportInvoices = async (company: string, exportIds: string[]) => {
  const response = await request(`/companies/${company}/invoices/export`, {
    method: 'POST',
    body: JSON.stringify(exportIds),
  });
  return response;
};

export const addInvoice = async (company: string, invoice: Partial<Invoice>) => {
  const { attachmentsToUpload, attachments, ...rest } = invoice;
  const response = await request(`/companies/${company}/invoices`, {
    method: 'POST',
    body: JSON.stringify(rest),
  });
  const result = await response.json();

  try {
    const form = new FormData();

    form.append('attachments', (attachments || []).map((a) => a.id).join(','));
    (attachmentsToUpload || []).forEach((file, i) => {
      form.append('attachmentsToUpload', file);
    });

    request(`/companies/${company}/invoices/${result.data.id}/attachments`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
      body: form,
    });
  } catch (e) {
    await removeInvoice(company, result.data.id);
    throw new Error('Failed to save invoice');
  }
  return [response, result];
};

export const bulkStatusUpdate = async (company: string, invoices: string[], status: Statuses) => request(`/companies/${company}/invoices/bulk/status/${status}`, {
  method: "PUT",
  body: JSON.stringify({ invoices })
});

export const updateInvoice = async (company: string, id: string, invoice: Partial<Invoice>) => {
  const { attachmentsToUpload, attachments, ...rest } = invoice;
  const response = await request(`/companies/${company}/invoices/${id}`, {
    method: 'PUT',
    body: JSON.stringify(rest),
  });
  const result = await response.json();
  try {
    const form = new FormData();

    form.append('attachments', (attachments || []).map((a) => a.id).join(','));
    (attachmentsToUpload || []).forEach((file, i) => {
      form.append('attachmentsToUpload', file);
    });

    request(`/companies/${company}/invoices/${id}/attachments`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
      body: form,
    });
  } catch (e) {
    await removeInvoice(company, result.data.id);
    throw new Error('Failed to save invoice');
  }
  return [response, result];
};

export const removeInvoice = (company: string, id: string) =>
  request(`/companies/${company}/invoices/${id}`, {
    method: 'DELETE',
  });
