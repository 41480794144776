import React, { forwardRef, useState, useContext } from 'react';
import classnames from 'classnames';
import { match, Switch, Route } from 'react-router';
import { Menu, Input, Button, Icon, Table, Loader, TableFooter } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Section } from '../../shared/Header';
import { PadlessSegment } from '../../shared/Segments';
import { InvoiceContext } from '../../contexts/InvoiceContext';
import { Notice } from '../../shared/Notice';
import { UserContext } from '../../contexts/UserContext';
import { LoadingWrapper } from '../../shared/Loading';
import { format as formatDate } from 'date-fns';
import { CustomerForm } from '../profile/customers/Form';
import { CustomerPeople } from '../profile/customers/CustomerPeople';
import { CustomerList } from '../profile/customers/CustomerList';
import { InvoiceForm } from './Form';
import { InvoiceList } from './InvoiceList';
import { CustomerCheck } from '../dashboard/CustomerCheck';
import { EnsureInvoices } from '../../fetchers/EnsureInvoices';
import { EnsureCustomers } from '../../fetchers/EnsureCustomers';

export interface InvoicesProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match;
}

export const Invoices = forwardRef<HTMLDivElement, InvoicesProps>(({ match, children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <CustomerCheck margin={{ bottom: true }} />

      <Switch>
        <Route path="/invoices/create" component={InvoiceForm} />
        <Route path="/invoices/:id/edit/:extra?" render={({ match }) => <InvoiceForm match={match} editing />} />
        <Route component={InvoiceList} />
      </Switch>
    </div>
  );
});
