import styled from 'styled-components';

export const Section = styled.div<{
  margin?: {
    top?: boolean;
    bottom?: boolean;
    around?: boolean;
  };
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.margin && props.margin.around && 'margin: 1.75em 0;'}
  ${(props) => props.margin && props.margin.top && 'margin-top: 1.75em;'}
  ${(props) => props.margin && props.margin.bottom && 'margin-bottom: 1.75em;'}
  h3 {
    margin-bottom: 0;
  }
`;
