export const validatePassword = (value: any = '') => {
  if (value.length >= 14) {
    return undefined;
  }

  if (value.length < 8) {
    return 'You need at least eight characters';
  }

  if (!/[a-z]/.test(value)) {
    return 'You need at least one lowercase character';
  }

  if (!/[A-Z]/.test(value)) {
    return 'You need at least one uppercase character';
  }

  if (!/[0-9]/.test(value)) {
    return 'You need at least one number';
  }

  return undefined;
};

export const mustBePositive = (value: any) => (value ? (value < 0 ? 'Must be positive' : undefined) : undefined);
