import React, { createContext, useState, FC, useMemo } from 'react';
import { Customer, customers as getCustomers } from '../lib/api/customers';
import { removeCustomer } from '../lib/api/companies';

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

const defaultCustomers: Customer[] = [];

export type CustomerContextState = {
  customers: Customer[];
  loading: Loading;
  hydrate: (company: string) => Promise<void>;
  remove: (company: string, id: string) => Promise<void>;
};

export const CustomerContext = createContext<CustomerContextState>({
  customers: defaultCustomers,
  loading: { loading: false, loaded: false, error: undefined },
  hydrate: () => Promise.resolve(),
  remove: () => Promise.resolve(),
});

export const CustomerProvider: FC = ({ children }) => {
  const [customers, setCustomers] = useState<Customer[]>(defaultCustomers);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined });
  const hydrate = async (company: string) => {
    try {
      setLoading({
        loaded: false,
        loading: true,
        error: undefined,
      });
      if (!loading.loading) {
        const customers = await getCustomers(company);
        setCustomers(
          customers.map((customer: any) => {
            customer.lastInvoice = customer.lastInvoice && new Date(customer.lastInvoice);
            return customer;
          }),
        );
      }
      setLoading({
        loaded: true,
        loading: false,
        error: undefined,
      });
    } catch (e) {
      setLoading({
        loaded: false,
        loading: false,
        error: e,
      });
    }
  };
  const value = useMemo(
    () => ({
      customers,
      loading,
      setLoading,
      hydrate,
      remove: async (company: string, id: string) => {
        await removeCustomer(company, id);
        await hydrate(company);
      },
    }),
    [customers, hydrate, loading],
  );

  return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>;
};
