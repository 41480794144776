import { request } from './base';

export type Card = {
  id: string;
  token: string;
  holder: string;
  identifier: string;
  expiry: string;
  default?: boolean;
};

export const cards = (company: string) =>
  request(`/companies/${company}/cards`)
    .then((res) => res.json())
    .then(({ data }) => data);

export const createCard = (company: string, card: any) =>
  request(`/companies/${company}/cards`, {
    method: 'POST',
    body: JSON.stringify(card),
  });

export const removeCard = (company: string, card: string) =>
  request(`/companies/${company}/cards/${card}`, {
    method: 'DELETE',
  });

export const setAsDefault = (company: string, card: string) =>
  request(`/companies/${company}/cards/${card}/default`, {
    method: 'POST',
  });

export type BillingHistory = {
  id: string;
  description: string;
  paymentMethod: string;
  card: string;
  amount: number;
  date: Date;
  company: string;
  status: string;
};

export const billingHistory = (company: string) =>
  request(`/companies/${company}/billing/history`)
    .then((res) => res.json())
    .then(({ data }) => data.map((item: any) => ({ ...item, date: new Date(item.date) })));
