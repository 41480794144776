import React, { forwardRef, useContext, useState } from 'react';
import classnames from 'classnames';
import { LoadingWrapper } from '../../shared/Loading';
import { PadlessSegment } from '../../shared/Segments';
import { Loader } from 'semantic-ui-react';
import { Table as T, TableRow, TableCell, Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { history } from '../../lib/history';
import { Status, dueBy } from '../invoices/InvoiceList';
import { Link } from 'react-router-dom';
import { Pager } from '../../shared/Pager';
import { Invoice } from '../../lib/api/invoices';
import { currencies } from '../../lib/currencies';
import BigNumber from 'bignumber.js';

export interface ReportResultProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  filters: any;
  setFilters: any;
  invoices: Invoice[];
  loading: any;
  totalInvoices: any;
  batchOperationIds: { [id: string]: boolean };
  setBatchOperationIds: any;
}

const Table = styled(T)`
  tr {
    cursor: pointer;
    &:hover {
      background-color: #f8f8f8;
    }
  }
`;

export const ReportResults = forwardRef<HTMLDivElement, ReportResultProps>(
  (
    { filters, setFilters, invoices, loading, totalInvoices, batchOperationIds, setBatchOperationIds, children, className, ...props },
    ref,
  ) => {
    const { pageSize, page } = filters;

    if (loading.loading) {
      return (
        <LoadingWrapper {...props} ref={ref} className={classnames('', {}, className)}>
          <Loader size="medium" active inline>
            Loading Results...
          </Loader>
        </LoadingWrapper>
      );
    }

    return (
      <div ref={ref} className={classnames('report__results-table-wrapper', {}, className)}>
        <PadlessSegment color="olive">
          <Table fixed sortable celled>
            <T.Header>
              <T.Row>
                <T.HeaderCell textAlign="right" width="one">
                  <Checkbox
                    label=""
                    name="select-all"
                    onChange={(event, { checked }) => {
                      setBatchOperationIds({
                        ...batchOperationIds,
                        ...invoices.reduce((values: any, inv: any) => {
                          values[inv.id] = checked;
                          return values;
                        }, {}),
                      });
                    }}
                  />
                </T.HeaderCell>
                <T.HeaderCell width="two">Status</T.HeaderCell>
                <T.HeaderCell width="two">Number</T.HeaderCell>
                <T.HeaderCell style={{ width: '120px' }}>Due By</T.HeaderCell>
                <T.HeaderCell width="two">Issue Date</T.HeaderCell>
                <T.HeaderCell width="eight">Customer</T.HeaderCell>
                <T.HeaderCell textAlign="right" width="two">
                  Value
                </T.HeaderCell>
              </T.Row>
            </T.Header>
            <T.Body>
              {invoices.length <= 0 && (
                <TableRow>
                  <TableCell colSpan={9}>There are no invoices for the selected criteria</TableCell>
                </TableRow>
              )}
              {invoices.map((invoice) => (
                <T.Row
                  key={invoice.id}
                  onClick={() => {
                    const location = invoice.status == 'Draft' ? `/invoices/${invoice.id}/edit` : `/invoices/${invoice.id}/edit/preview`;
                    return window.open(location, '_blank');
                  }}
                >
                  <T.Cell textAlign="right">
                    <Checkbox
                      label=""
                      name={invoice.id}
                      checked={batchOperationIds[invoice.id]}
                      onChange={(event, { checked, name }) => {
                        setBatchOperationIds({
                          ...batchOperationIds,
                          [name as any]: checked,
                        });
                      }}
                    />
                  </T.Cell>
                  <T.Cell key={invoice.id}>
                    <Status status={invoice.status} />
                  </T.Cell>
                  <T.Cell>
                    <a href="#">{invoice.number}</a>
                  </T.Cell>
                  <T.Cell>{dueBy(invoice.due as Date)}</T.Cell>
                  <T.Cell>{(invoice.issued as Date).toLocaleDateString()}</T.Cell>
                  <T.Cell>
                    <span className="invoice-list__customer-item">{invoice.customerDisplayName}</span>
                    <span className="invoice-list__subject-item">{invoice.customerReference}</span>
                  </T.Cell>
                  <T.Cell textAlign="right">
                    {currencies[invoice.currency].symbol} {new BigNumber(invoice.total || 0).toFormat(2)}
                  </T.Cell>
                </T.Row>
              ))}
            </T.Body>
            <T.Footer>
              <T.Row>
                <T.HeaderCell colSpan={6} textAlign="right">
                  <b>Total Vat</b>
                </T.HeaderCell>
                <T.HeaderCell textAlign="right">
                  {invoices.length > 0 ? (
                    <>
                      {Object.entries(
                        invoices.reduce((totals: { [index: string]: BigNumber }, invoice) => {
                          if (!totals[invoice.currency]) {
                            totals[invoice.currency] = new BigNumber(0);
                          }
                          totals[invoice.currency] = totals[invoice.currency].plus(invoice.totalVat || 0);
                          return totals;
                        }, {}),
                      ).map(([currency, value]) => {
                        return (
                          <React.Fragment key={currency}>
                            {currencies[currency].symbol} {value.toFormat(2)} <br />
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    'n/a'
                  )}
                </T.HeaderCell>
              </T.Row>
              <T.Row>
                <T.HeaderCell colSpan={6} textAlign="right">
                  <b>Total</b>
                </T.HeaderCell>
                <T.HeaderCell textAlign="right">
                  {invoices.length > 0 ? (
                    <>
                      {Object.entries(
                        invoices.reduce((totals: { [index: string]: BigNumber }, invoice) => {
                          if (!totals[invoice.currency]) {
                            totals[invoice.currency] = new BigNumber(0);
                          }
                          totals[invoice.currency] = totals[invoice.currency].plus(invoice.total || 0);
                          return totals;
                        }, {}),
                      ).map(([currency, value]) => {
                        return (
                          <React.Fragment key={currency}>
                            {currencies[currency].symbol} {value.toFormat(2)} <br />
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    'n/a'
                  )}
                </T.HeaderCell>
              </T.Row>
            </T.Footer>
          </Table>
        </PadlessSegment>

        <Pager
          page={{
            size: pageSize || 50,
            number: page || 1,
            total: totalInvoices,
          }}
          onChange={({ size, number }) => {
            setFilters({
              ...filters,
              pageSize: size,
              page: number,
            });
          }}
        />
      </div>
    );
  },
);
