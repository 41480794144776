import React, { FC, useCallback, useEffect, useState, createElement } from 'react';
import { FieldRenderProps } from 'react-final-form';
import {
  FormFieldProps,
  Form,
  Input,
  InputProps,
  DropdownProps,
  Checkbox,
  Segment,
  Header,
  Image,
  Icon,
  Button,
  SemanticICONS,
} from 'semantic-ui-react';
import Select from 'react-select';
import { Props as SelectProps } from 'react-select/src/Select';
import { useDropzone } from 'react-dropzone';
import { currencies } from '../lib/currencies';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { format } from 'date-fns';
import parse from 'date-fns/parse';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import CreatableSelect from 'react-select/creatable';

const FieldWrapper = styled.div<{ hideError?: boolean }>`
  display: flex;
  flex-direction: column;
  &&&&&& {
    margin-bottom: 21px;
    ${(props) =>
      props.hideError &&
      css`
        margin-bottom: 0;
      `}
    &.error {
      margin-bottom: 2px;
      ${(props) =>
        props.hideError &&
        css`
          margin-bottom: 0;
        `}
    }
  }
`;

export const FormField: FC<FieldRenderProps<{}, HTMLElement> & FormFieldProps & { hideError?: boolean }> = ({
  hideError,
  input,
  label,
  control,
  type,
  meta,
  style,
  ...props
}) => {
  const hasError = meta.invalid && meta.touched;
  const inputProps = { ...props, ...input, label: undefined, error: hasError };
  return (
    <FieldWrapper style={style} hideError={hideError} className={classnames('field', { error: hasError })}>
      {label && input.type != 'checkbox' && <label>{label}</label>}
      {createElement(control || Input, { ...inputProps })}
      {!hideError && (
        <span
          style={{
            fontSize: '0.8rem',
            color: '#dd324f',
          }}
        >
          {hasError ? meta.error || meta.submitError : ''}
        </span>
      )}
    </FieldWrapper>
  );
};

export const CashInput: FC<InputProps> = (props) => <Input {...props} type="number" label="R" />;

export const PercentageInput: FC<InputProps> = ({ onChange, value, ...props }) => (
  <Input
    {...props}
    value={value * 100}
    onChange={(e, data) => {
      e.target.value = +e.target.value / 100 + '';
      onChange && onChange(e, data);
    }}
    type="number"
    label="%"
  />
);

export const EmailInput: FC<InputProps> = (props) => <Input {...props} type="email" icon="mail" iconPosition="left" />;
export const SearchInput: FC<InputProps> = (props) => <Input {...props} icon="search" iconPosition="left" />;

export const PasswordInput: FC<InputProps> = (props) => <Input {...props} type="password" icon="key" iconPosition="left" />;

export const ControlledCheckbox: FC<InputProps> = ({ onChange, value, label, ...props }) => (
  <Checkbox
    {...props}
    type="checkbox"
    label={label}
    checked={value}
    onChange={(value, target) => {
      (onChange as any)({ ...value, target });
    }}
  />
);

export const Dropdown: FC<SelectProps & InputProps> = (props: any) => <Select {...props} />;

export const CreateableDropdown: FC<SelectProps & InputProps> = (props: any) => <CreatableSelect {...props} />;

export const CurrencySelection: FC<SelectProps & InputProps> = (props: any) => (
  <Select
    {...props}
    options={[
      { value: '', label: '' },
      ...Object.values(currencies).map((currency) => {
        return { value: currency.code, label: currency.name };
      }),
    ]}
  />
);

const DisplayImage: FC<{ image?: File }> = ({ image }) => {
  const [data, setData] = useState('');
  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        setData(e.target.result);
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  if (!data) {
    return null;
  }

  return <Image style={{ width: 200 }} src={data} />;
};

export const ImageUpload: FC<{ icon: SemanticICONS; onDrop(acceptedFiles: File[]): void; file?: File }> = ({
  icon,
  onDrop,
  file,
  ...props
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Segment placeholder style={{ minHeight: 'auto', flex: 1 }} {...(getRootProps() as any)}>
      {file ? (
        <Header icon>
          <input style={{ display: 'none' }} {...getInputProps()} />
          <DisplayImage image={file} />
          {/* <Icon name={isDragActive ? icon : 'upload'} /> */}
        </Header>
      ) : (
        <Header icon>
          <input style={{ display: 'none' }} {...getInputProps()} />
          <Icon name={isDragActive ? icon : 'upload'} />
          Drop your image here to upload it
        </Header>
      )}
    </Segment>
  );
};
