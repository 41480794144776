import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { Menu, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { Switch, Route, match } from 'react-router';
import { Link } from 'react-router-dom';
import { CompanyDetails } from './company';
import { Billing } from './billing';
import { Subscription } from './subscription';
import { ProfileDetails } from './details';
import { profileColors } from './shared';

export interface ProfileProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match;
}

export const Profile = forwardRef<HTMLDivElement, ProfileProps>(({ children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <Switch>
        <Route path="/profile/company/:route?" component={CompanyDetails} />
        <Route path="/profile/billing/:route?" component={Billing} />
        <Route path="/profile/subscription" component={Subscription} />
        <Route path="/profile/subscribe" component={Subscription} />
        <Route component={ProfileDetails} />
      </Switch>
    </div>
  );
});
