import React, { forwardRef, useState, useEffect, FC, useContext } from 'react';
import classnames from 'classnames';
import { Grid, Image, Header, Form, Segment, Button, Message, Input } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Redirect, Link, match, Route } from 'react-router-dom';
import { EmailInput, FormField, PasswordInput } from '../../shared/Fields';
import { request } from '../../lib/api/base';
import { UserContext } from '../../contexts/UserContext';
import { validatePassword } from '../../lib/validation';
import { CompanyContext } from '../../contexts/CompanyContext';
import bg from './background.svg';
import logo from '../../assets/logo.svg';
import { extractValidationError } from '../../lib/utils';
import { LoginForm } from './Login';
import { SignUp } from './Signup';
import { ForgotPassword } from './ForgotPassword';
import { PasswordReset } from './PasswordReset';
import styled from 'styled-components';

export interface LoginProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match<{ token: string }>;
}

export const DelayedRedirect: FC<{ to: string; wait: number }> = ({ to, wait }) => {
  const [go, setGo] = useState(false);
  useEffect(() => {
    setTimeout(() => setGo(true), wait);
  }, [to, wait]);
  if (go) {
    return <Redirect to={to} />;
  }

  return null;
};

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  background-repeat: no-repeat;
  background-color: #f8f8f8;
  @media (min-width: 768px) {
    background-position: right top;
  }
`;

export const Login = forwardRef<HTMLDivElement, LoginProps>(({ match, children, className, ...props }, ref) => {
  const { user, hydrate } = useContext(UserContext);
  const { company, loading } = useContext(CompanyContext);

  return (
    <Wrapper
      ref={ref}
      className={classnames('', {}, className)}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <Segment style={{ width: 450, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
        <Image src={logo} size="medium" style={{ paddingBottom: '4em', margin: 'auto' }} />
        <Route path="/login" component={LoginForm} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/password-reset/:token" component={PasswordReset} />
      </Segment>
    </Wrapper>
  );
});
