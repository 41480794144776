import React, { forwardRef, useContext, useState } from 'react';
import classnames from 'classnames';
import { UserContext } from '../../../contexts/UserContext';
import { CustomerContext } from '../../../contexts/CustomerContext';
import { LoadingWrapper } from '../../../shared/Loading';
import {
  Loader,
  Button,
  Icon,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Header,
  Segment,
  Grid,
  Form,
  Input,
} from 'semantic-ui-react';
import { Field, Form as FinalForm } from 'react-final-form';
import { FormField, Dropdown, CashInput, PercentageInput, EmailInput } from '../../../shared/Fields';
import { Notice } from '../../../shared/Notice';
import { Link, match } from 'react-router-dom';
import { CustomerPeopleContext } from '../../../contexts/CustomerPeopleContext';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { EnsureCustomerPeople } from '../../../fetchers/EnsureCustomerPeople';
import { extractValidationError } from '../../../lib/utils';
import { createPerson, updatePerson } from '../../../lib/api/customers';
import { company } from '../../../lib/api/companies';
import { Person } from '../../../lib/api/people';
import { formSubscriptionItems } from 'final-form';
import { PadlessSegment } from '../../../shared/Segments';

export interface CustomerPeopleProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match<{ id: string }>;
}

export const CustomerPeople = forwardRef<HTMLDivElement, CustomerPeopleProps>(({ children, className, match, ...props }, ref) => {
  const { user, loading: userLoading } = useContext(UserContext);
  const [selectedPerson, setSelectedPerson] = useState<Partial<Person>>({});
  const { people, loading, hydrate, remove } = useContext(CustomerPeopleContext);
  const [toRemove, setToRemove] = useState('');
  const { customers } = useContext(CustomerContext);
  const customer = customers.find((customer) => customer.id == match.params.id) || { name: '' };
  if (!userLoading.loaded || loading.loading || !loading.loaded) {
    return (
      <LoadingWrapper {...props} ref={ref} className={classnames('', {}, className)}>
        <EnsureCustomerPeople match={match} />
        <Loader size="medium" active inline>
          Loading your people...
        </Loader>
      </LoadingWrapper>
    );
  }
  if (loading.error) {
    return (
      <Notice icon="exclamation circle" message="Failed to load your people!">
        <Button onClick={() => hydrate(user.company || '', match.params.id)}>Try again</Button>
      </Notice>
    );
  }
  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <EnsureCustomerPeople match={match} />
      <Header>
        <h3>People for {customer.name}</h3>
      </Header>
      <Grid>
        <Grid.Row>
          <Grid.Column width="5">
            <Segment color="olive">
              <FinalForm
                onSubmit={async (values: any) => {
                  const response = await (selectedPerson.id
                    ? updatePerson(user.company || '', match.params.id, selectedPerson.id || '', values)
                    : createPerson(user.company || '', match.params.id, values));
                  if (response.status == 422) {
                    const validation = await response.json();
                    return extractValidationError(validation);
                  }
                  if (response.status != 200) {
                    throw new Error('Unable to save');
                  }
                  await hydrate(user.company || '', match.params.id);
                  setSelectedPerson({});
                }}
                initialValues={selectedPerson || {}}
                render={({ handleSubmit, submitting, form }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field
                      name="firstName"
                      validate={(value) => !value && 'Your person must have a name'}
                      label="First Name"
                      placeholder="e.g. Bob"
                      control={Input}
                      component={FormField}
                    />
                    <Field name="lastName" label="Last Name" placeholder="e.g. Ross" control={Input} component={FormField} />

                    <Field
                      name="email"
                      validate={(value: any) => {
                        if (!value) {
                          return 'Your invitee must have a email';
                        }
                        if (!(selectedPerson.email == value) && people.map((p) => p.email).includes(value)) {
                          return 'This person is already a member of your customer';
                        }
                      }}
                      label="Email"
                      placeholder="e.g. bob@example.com"
                      control={EmailInput}
                      component={FormField}
                    />
                    <Field
                      name="contactNumber"
                      label="Contact Number"
                      placeholder="e.g. 022 231 4410"
                      control={Input}
                      component={FormField}
                    />
                    <Field name="language" label="Language" placeholder="e.g. English" control={Input} component={FormField} />
                    {selectedPerson.id ? (
                      <Button.Group>
                        <Button color="olive" loading={submitting} type="submit">
                          Save
                        </Button>
                        <Button onClick={() => form.reset()}>Reset</Button>
                        <Button loading={submitting} onClick={() => setSelectedPerson({})} type="submit">
                          Cancel
                        </Button>
                        <Button as={Link} to="/customers">
                          Back
                        </Button>
                      </Button.Group>
                    ) : (
                      <Button.Group>
                        <Button color="olive" loading={submitting} type="submit">
                          Add
                        </Button>
                        <Button onClick={() => form.reset()}>Reset</Button>
                        <Button as={Link} to="/customers">
                          Back
                        </Button>
                      </Button.Group>
                    )}
                  </Form>
                )}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width="11">
            <PadlessSegment color="olive">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Language</TableHeaderCell>
                    <TableHeaderCell>Email</TableHeaderCell>
                    <TableHeaderCell>Contact Number</TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {people.map((person) => (
                    <TableRow key={person.id}>
                      <TableCell>
                        {person.firstName} {person.lastName}
                      </TableCell>
                      <TableCell>{person.language}</TableCell>
                      <TableCell>{person.email}</TableCell>
                      <TableCell>{person.contactNumber}</TableCell>
                      <TableCell>
                        <Button.Group size="mini" floated="right">
                          {toRemove == person.id ? (
                            <>
                              <Button
                                size="mini"
                                color="pink"
                                onClick={() => {
                                  remove(user.company, match.params.id, person.id);
                                  setToRemove('');
                                }}
                              >
                                <Icon name="pencil" />
                                Confirm
                              </Button>
                              <Button size="mini" onClick={() => setToRemove('')} color="olive">
                                <Icon name="x" />
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button onClick={() => setSelectedPerson(person)} size="mini" color="olive">
                                <Icon name="pencil" />
                                Edit
                              </Button>
                              <Button size="mini" onClick={() => setToRemove(person.id)} color="pink">
                                <Icon name="trash" />
                                Remove
                              </Button>
                            </>
                          )}
                        </Button.Group>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PadlessSegment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
});
