import React, { forwardRef, useContext, HTMLAttributes, useState } from 'react';
import classnames from 'classnames';
import { SegmentProps, Segment, Grid, Form, Input, Button, Table, Message } from 'semantic-ui-react';
import { profileColors } from '../shared';
import { invite, company, revokeInvite } from '../../../lib/api/companies';
import { extractValidationError } from '../../../lib/utils';
import { hydrate } from 'react-dom';
import { Field, Form as FinalForm } from 'react-final-form';
import { EmailInput, FormField } from '../../../shared/Fields';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { PadlessSegment } from '../../../shared/Segments';
import { UserContext } from '../../../contexts/UserContext';

export interface CompanyMembersProps extends HTMLAttributes<HTMLDivElement> {}

const userCounts: { [index: string]: any } = {
  Starter: 1,
};

export const CompanyMembers = forwardRef<HTMLDivElement, CompanyMembersProps>(({ children, className, ...props }, ref) => {
  const { company, loading, hydrate } = useContext(CompanyContext);
  const maxUsers = userCounts[company.subscription] ? userCounts[company.subscription] <= company.people.length : false;
  const [toRemove, setToRemove] = useState();

  return (
    <div ref={ref} className={classnames('', {}, className)} color={profileColors.company}>
      <h3>Invite new members to your company</h3>
      {maxUsers && <Message>You are only allowed up to {userCounts[company.subscription]} users on your current subscription</Message>}
      <Grid>
        <Grid.Row>
          <Grid.Column width="16">
            <Segment color={profileColors.company}>
              <FinalForm
                onSubmit={async (values: any) => {
                  const response = await invite(company.id, values);
                  if (response.status == 422) {
                    const validation = await response.json();
                    return extractValidationError(validation);
                  }
                  if (response.status != 200) {
                    throw new Error('Unable to save');
                  }
                  await hydrate(company.id);
                }}
                render={({ handleSubmit, submitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field
                      name="displayName"
                      validate={(value) => !value && 'Your invitee must have a name'}
                      label="Name"
                      placeholder="e.g. Bob ross"
                      control={Input}
                      component={FormField}
                    />
                    <Field
                      name="email"
                      validate={(value: any) => {
                        if (!value) {
                          return 'Your invitee must have a email';
                        }
                        if (company.people.map((p) => p.email).includes(value)) {
                          return 'This person is already a member of your company';
                        }
                      }}
                      label="Email"
                      placeholder="e.g. bob@example.com"
                      control={EmailInput}
                      component={FormField}
                    />
                    <Button disabled={maxUsers} loading={submitting} type="submit">
                      Invite!
                    </Button>
                  </Form>
                )}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <PadlessSegment color={profileColors.company}>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {company.people.map((person) => (
                    <Table.Row key={person.id}>
                      <Table.Cell>
                        {person.firstName} {person.lastName}
                      </Table.Cell>
                      <Table.Cell>{person.email}</Table.Cell>
                      <Table.Cell>
                        {toRemove == person.id ? (
                          <Button.Group size="tiny">
                            <Button
                              color="pink"
                              onClick={async () => {
                                await revokeInvite(company.id, person.id);
                                await hydrate(company.id);
                              }}
                            >
                              Confirm
                            </Button>
                            <Button onClick={() => setToRemove(undefined)}>Cancel</Button>
                          </Button.Group>
                        ) : (
                          <Button size="tiny" onClick={() => setToRemove(person.id)} disabled={company.people.length == 1}>
                            Remove
                          </Button>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </PadlessSegment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
});
