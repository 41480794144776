import React, { forwardRef, useContext } from 'react';
import classnames from 'classnames';
import { Segment, Form, Grid, Input, TextArea, Button } from 'semantic-ui-react';
import { Field, Form as FinalForm } from 'react-final-form';
import { FormField, Dropdown, CashInput, PercentageInput, EmailInput, CurrencySelection } from '../../../shared/Fields';
import { Customer, create as createCustomer, update as updateCustomer } from '../../../lib/api/customers';
import { CustomerContext } from '../../../contexts/CustomerContext';
import { UserContext } from '../../../contexts/UserContext';
import { company } from '../../../lib/api/companies';
import { LookupContext } from '../../../contexts/LookupsContext';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { extractValidationError } from '../../../lib/utils';
import { history } from '../../../lib/history';
import { match } from 'react-router';
import { Link } from 'react-router-dom';
import { CustomerPeopleContext } from '../../../contexts/CustomerPeopleContext';
import { EnsureCustomerPeople } from '../../../fetchers/EnsureCustomerPeople';
import { currencies } from '../../../lib/currencies';

export interface CustomerFormProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  editing?: boolean;
  match: match<{ id: string }>;
}

const paymentTerms = [
  { value: 0, label: 'Upon Reciept' },
  { value: 15, label: 'Net 15' },
  { value: 20, label: 'Net 20' },
  { value: 30, label: 'Net 30' },
  { value: 60, label: 'Net 60' },
];

export const CustomerForm = forwardRef<HTMLDivElement, CustomerFormProps>(({ match, children, editing, className, ...props }, ref) => {
  const { user } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const { hydrate, customers } = useContext(CustomerContext);
  const { lookups } = useContext(LookupContext);
  const customer = customers.find((customer) => customer.id == match.params.id);
  const { people, loading, remove } = useContext(CustomerPeopleContext);

  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <h3>{!customer ? 'Create a customer' : `Edit ${(customer || { name: '' }).name}`}</h3>
      <Segment color="olive">
        <EnsureCustomerPeople match={match} />
        <FinalForm<any>
          initialValues={
            customer
              ? Object.assign({}, customer, {
                  paymentTerms: {
                    value: customer.paymentTerms,
                    label: (paymentTerms.find((term) => term.value == customer.paymentTerms) || { label: null }).label,
                  },
                  currency: { value: customer.currency, label: currencies[customer.currency].name },
                })
              : {
                  company: user.company,
                  vatRate: 0.15,
                  category: 'Retail',
                  paymentTerms: paymentTerms[1],
                  currency: { value: currencies.USD.code, label: currencies.USD.name },
                }
          }
          onSubmit={async (values) => {
            const customer = {
              ...values,
              paymentTerms: values.paymentTerms.value || 0,
              currency: values.currency.value || '',
            } as Customer;
            const response = await (editing ? updateCustomer(company.id, values.id || '', customer) : createCustomer(company.id, customer));

            if (response.status == 422) {
              const validation = await response.json();
              return extractValidationError(validation);
            }
            if (response.status != 200) {
              throw new Error('Unable to save');
            }

            await hydrate(company.id);

            history.push('/customers');
          }}
          render={({ handleSubmit, submitting, dirty, form }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <Form.Group widths="three">
                        <Field
                          name="name"
                          validate={(value) => !value && 'Your customer must have a name'}
                          label="Customer Name"
                          placeholder="e.g. Invico "
                          control={Input}
                          component={FormField}
                        />
                        <Field
                          name="vatNumber"
                          type="number"
                          label="Tax Number"
                          placeholder="e.g. 8883420123"
                          control={Input}
                          component={FormField}
                        />
                        <Field name="website" label="Website" placeholder="e.g. www.example.com" control={Input} component={FormField} />
                      </Form.Group>

                      <Form.Group widths="three">
                        <Field
                          validate={(value: any) => {
                            if (value.value === 0) return undefined;
                            return !value.value && 'Payment terms are required';
                          }}
                          name="paymentTerms"
                          label="Payment Terms"
                          control={Dropdown}
                          options={paymentTerms}
                          component={FormField}
                        />
                        <Field
                          name="vatRate"
                          label="Tax Rate"
                          validate={(value: any) => !value && 'Tax rate is required'}
                          control={PercentageInput}
                          component={FormField}
                        />
                        <Field
                          name="currency"
                          label="Currency"
                          validate={(value: any) => !value.value && 'Currency is required'}
                          control={CurrencySelection}
                          component={FormField}
                        />
                      </Form.Group>

                      <Form.Group grouped>
                        <Field
                          name="defaultNote"
                          label="Default Invoice Note"
                          rows={12}
                          placeholder={`e.g. Line items broken down by staff member`}
                          control={TextArea}
                          component={FormField}
                        />
                      </Form.Group>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Form.Group grouped>
                        <Field
                          name="contactName"
                          validate={(value) => !value && 'Your customer must have a contact person'}
                          label="Contact Person"
                          placeholder="e.g. James Almost"
                          control={Input}
                          component={FormField}
                        />
                        <Field
                          name="contactEmail"
                          validate={(value) => !value && 'Your customer must have a contact email'}
                          label="Customer Email"
                          placeholder="e.g. james@example.com"
                          control={Input}
                          component={FormField}
                        />
                        <Field
                          name="contactNumber"
                          label="Contact Number"
                          placeholder="e.g. 002 0032 222"
                          control={Input}
                          component={FormField}
                        />
                        <Field
                          name="address"
                          label="Address"
                          rows={7}
                          placeholder={`e.g.\n21 Willoughby way\nThe Adderlands\n20001\nNew London`}
                          control={TextArea}
                          component={FormField}
                        />
                      </Form.Group>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Button.Group floated="right">
                        <Button as={Link} to="/customers">
                          Back
                        </Button>
                        <Button type="button" disabled={!dirty} onClick={() => form.reset()}>
                          Reset
                        </Button>
                        <Button type="submit" loading={submitting} color="olive">
                          Save
                        </Button>
                      </Button.Group>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            );
          }}
        />
      </Segment>
    </div>
  );
});
