import React, { forwardRef, useState, FC, useContext } from 'react';
import classnames from 'classnames';
import { match, useRouteMatch } from 'react-router';
import { Segment } from '../../../shared/Segments';
import { profileColors } from '../shared';
import visa from './VISA-logo.png';
import mastercard from './mastercard-logo.png';
import { Image, Form, Input, Tab, Button, Loader, Icon, Message, ButtonGroup, Table, TabPane } from 'semantic-ui-react';
import CreditCard from 'react-credit-cards';
import { Form as FinalForm, Field } from 'react-final-form';
import { PercentageInput, FormField } from '../../../shared/Fields';
import styled from 'styled-components';
import { BillingContext } from '../../../contexts/BillingContext';
import { LoadingWrapper } from '../../../shared/Loading';
import { createCard, setAsDefault } from '../../../lib/api/billing';
import { UserContext } from '../../../contexts/UserContext';
import { extractValidationError } from '../../../lib/utils';
import { history } from '../../../lib/history';
import { Link } from 'react-router-dom';
import { CompanyContext } from '../../../contexts/CompanyContext';
import BigNumber from 'bignumber.js';

export interface BillingProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match<{ route?: string | undefined }>;
}

export const CardHolder = styled.div`
  margin-bottom: 1em;
  margin-right: 1em;
`;

const AddCardHolder = styled.div`
  margin-right: 1em;
`;

const CardList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 1em;
  .rccs__card--front {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .buttons {
    overflow: hidden;
  }
`;

export const Cards: FC = ({}) => {
  const { hydrate: hydrateCompany } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const { cards, loading, remove, hydrate } = useContext(BillingContext);
  const [removing, setRemoving] = useState('');

  if (!loading.loaded) {
    return (
      <LoadingWrapper>
        <Loader size="medium" active inline>
          Fetching your cards...
        </Loader>
      </LoadingWrapper>
    );
  }

  if (cards.length <= 0) {
    return (
      <div>
        <h4>You don't have any cards yet</h4>
        <CardHolder>
          <Button as={Link} to="/profile/billing/new-card" color={profileColors.billing} icon labelPosition="right">
            <Icon name="add circle" />
            Add one now
          </Button>
        </CardHolder>
      </div>
    );
  }

  return (
    <CardList>
      {cards.map((card) => (
        <CardHolder key={card.id}>
          <CreditCard number={card.identifier} cvc="" name={card.holder} expiry={card.expiry} />
          <ButtonGroup size="mini" attached="bottom">
            {card.default ? (
              <Button color={profileColors.billing}>Default</Button>
            ) : removing == card.id ? (
              <>
                <Button onClick={() => setRemoving('')}>Cancel</Button>
                <Button color="pink" onClick={() => remove(user.company, card.id)}>
                  Confirm
                </Button>
              </>
            ) : (
              <>
                <Button color="pink" onClick={() => setRemoving(card.id)}>
                  Remove
                </Button>
                <Button
                  onClick={async () => {
                    await setAsDefault(user.company, card.id);
                    await Promise.all([hydrate(user.company), hydrateCompany(user.company)]);
                  }}
                >
                  Set as default
                </Button>
                )}
              </>
            )}
          </ButtonGroup>
        </CardHolder>
      ))}
    </CardList>
  );
};

const History: FC = ({}) => {
  const { hydrate: hydrateCompany } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const { history, loading, remove, hydrate } = useContext(BillingContext);
  const [removing, setRemoving] = useState('');

  if (!loading.loaded) {
    return (
      <LoadingWrapper>
        <Loader size="medium" active inline>
          Fetching your cards...
        </Loader>
      </LoadingWrapper>
    );
  }

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Card</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {history.length <= 0 && (
            <Table.Row>
              <Table.Cell colSpan={5}>You don't have a billing history yet</Table.Cell>
            </Table.Row>
          )}
          {history.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>{item.date.toLocaleDateString()}</Table.Cell>
              <Table.Cell>{item.description}</Table.Cell>
              <Table.Cell>{item.card}</Table.Cell>
              <Table.Cell>{item.status}</Table.Cell>
              <Table.Cell>R {new BigNumber(item.amount).toFormat(2)}</Table.Cell>
              <Table.Cell>
                {item.status == 'Success' && (
                  <Button as="a" href={`/api/companies/${user.company}/billing/history/${item.id}.pdf`} size="tiny">
                    Invoice
                  </Button>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export const AddCard: FC<{ redirectAfterSubmit?: boolean }> = ({ redirectAfterSubmit = true }) => {
  const [focus, setFocus] = useState(undefined);
  const { user } = useContext(UserContext);
  const { hydrate } = useContext(BillingContext);
  const { hydrate: hydrateCompany } = useContext(CompanyContext);

  return (
    <FinalForm<{ cvc: string; expiry: string; name: string; number: string }>
      initialValues={{
        number: '',
        name: '',
        expiry: '',
        cvc: '',
      }}
      onSubmit={async (values) => {
        try {
          const response = await createCard(user.company, { ...values, number: values.number.replace(/\s/g, '') });

          if (response.status == 422) {
            const result = await response.json();
            if (result.message) {
              return result;
            }
            return extractValidationError(result);
          }

          if (response.status != 200) {
            const result = await response.json();
            throw new Error(result.message);
          }

          await Promise.all([hydrate(user.company), hydrateCompany(user.company)]);

          if (redirectAfterSubmit) {
            history.push('/profile/billing');
          }
        } catch (e) {
          return { message: e.message };
        }
      }}
      render={({ handleSubmit, values, submitting, submitErrors }) => {
        const card = {
          number: '',
          name: '',
          expiry: '',
          cvc: '',
          ...values,
        };
        return (
          <div>
            {submitErrors && <Message warn>{submitErrors.message}</Message>}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <AddCardHolder>
                <CreditCard {...card} focused={focus} />
              </AddCardHolder>
              <Form loading={submitting} onSubmit={handleSubmit} onFocus={(e: any) => setFocus(e.target.name)}>
                <Form.Group widths="equal">
                  <Field
                    name="number"
                    label="Card number"
                    placeholder="e.g. 5239 **** **** 2312"
                    control={Input}
                    component={FormField}
                    validate={(value: any) => {
                      if (value.replace(/\s/g, '').length != 16) {
                        return 'Card number must be 16 numbers';
                      }
                    }}
                  />
                  <Field
                    name="name"
                    label="Cardholder"
                    placeholder="James almost"
                    control={Input}
                    component={FormField}
                    validate={(value) => !value && 'Cardholder is required'}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Field
                    name="expiry"
                    label="Expiry"
                    placeholder="e.g. 11/26"
                    control={Input}
                    component={FormField}
                    validate={(value: any = '') => (!value.includes('/') || value.length != 5) && 'Expiry must look like 01/02'}
                  />
                  <Field
                    name="cvc"
                    label="CVC"
                    placeholder="e.g. 923"
                    control={Input}
                    component={FormField}
                    validate={(value) => !value && 'CVC is required'}
                  />
                </Form.Group>
                <Button loading={submitting} color={profileColors.billing}>
                  Add
                </Button>
              </Form>
            </div>
          </div>
        );
      }}
    />
  );
};

const tabs = ['', 'cards', 'new-card'];

export const Billing = forwardRef<HTMLDivElement, BillingProps>(({ match, children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <Tab
        activeIndex={match.params.route ? tabs.indexOf(match.params.route) : 0}
        onTabChange={(_, { activeIndex }) =>
          history.push(`/profile/billing${tabs[activeIndex as any] ? `/${tabs[activeIndex as any]}` : ``}`)
        }
        panes={[
          {
            menuItem: 'Billing History',
            render: () => (
              <Tab.Pane>
                <History />
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Cards',
            render: () => (
              <Tab.Pane>
                <Cards />
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Add a new card',
            render: () => (
              <Tab.Pane>
                <AddCard />
              </Tab.Pane>
            ),
          },
        ]}
      />
    </div>
  );
});
