import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router';
import { UserContext } from '../contexts/UserContext';
import { LookupContext } from '../contexts/LookupsContext';

export const EnsureLookups: React.FunctionComponent<any> = ({ match }) => {
  const { loading, hydrate } = useContext(LookupContext);

  useEffect(() => {
    if (!loading.loading && !loading.loaded && !loading.error) hydrate();
  }, [hydrate, loading]);

  return null;
};
