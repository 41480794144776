import React, { forwardRef, useContext, HTMLAttributes, useState } from 'react';
import classnames from 'classnames';
import { Form, Grid, Input, TextArea, Button, SegmentProps, Step, Image, Loader, Card } from 'semantic-ui-react';
import { Company, updateCompany, company } from '../../../lib/api/companies';
import { extractValidationError } from '../../../lib/utils';
import { hydrate } from 'react-dom';
import { Field, Form as FinalForm } from 'react-final-form';
import { Dropdown, FormField, ImageUpload } from '../../../shared/Fields';
import { CompanyContext, isCompanySetup } from '../../../contexts/CompanyContext';
import { profileColors } from '../shared';
import { LoadingWrapper } from '../../../shared/Loading';
import styled from 'styled-components';
import { Segment } from '../../../shared/Segments';
import { countries } from '../../../lib/countries';

export interface CompanyFormProps extends HTMLAttributes<HTMLDivElement> {}

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  position: relative;
  overflow: hidden;
  .ui.image {
    width: 100%;
  }
`;

const UploadOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  width: 100%;
  padding-left: 0.25em;
  top: 100%;
  left: 100%;
  cursor: pointer;
  transform: translate(-100%, -100%);
  :hover {
    text-transform: underline;
  }
`;

const financialYearStarts = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

export const CompanyForm = forwardRef<HTMLDivElement, CompanyFormProps>(({ children, className, ...props }, ref) => {
  const { company, loading, hydrate } = useContext(CompanyContext);
  const companyOriginallySetup = isCompanySetup(company);

  if (!loading.loaded) {
    return (
      <LoadingWrapper {...props} ref={ref} className={classnames('', {}, className)}>
        <Loader size="medium" active inline>
          Fetching your company...
        </Loader>
      </LoadingWrapper>
    );
  }

  return (
    <div ref={ref} className={classnames('', {}, className)} color={profileColors.company}>
      <FinalForm<Company & { logo?: File; financialYearStart: { label: string; value: number }; country: { label: string; value: string } }>
        onSubmit={async (comp) => {
          const { financialYearStart, country, ...company } = comp;
          const response = await updateCompany(company.id, {
            ...company,
            financialYearStart: financialYearStart.value,
            country: country.value,
          });
          if (response.status == 422) {
            const validation = await response.json();
            return extractValidationError(validation);
          }
          if (response.status != 200) {
            throw new Error('Unable to save');
          }
          await hydrate(company.id);
        }}
        initialValues={Object.assign({}, company, {
          financialYearStart: company.financialYearStart
            ? financialYearStarts.find((v) => v.value == company.financialYearStart)
            : { value: 1, label: 'Jan' },
          country: company.country ? countries.find((v) => v.value == company.country) : { value: 'US', label: 'United States' },
        })}
        render={({ handleSubmit, submitting, values, form, dirty }) => {
          const [editingLogo, setEditingLogo] = useState(false);

          return (
            <Form onSubmit={handleSubmit}>
              <h3>Company details</h3>

              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Segment color={profileColors.company}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={4}>
                            <Card>
                              {editingLogo ? (
                                <>
                                  <ImageUpload
                                    icon="building"
                                    file={(values as any).logo}
                                    onDrop={(files) => form.change('logo', files[0])}
                                  />
                                </>
                              ) : (
                                <>
                                  <Image
                                    style={{ padding: '1em' }}
                                    size="large"
                                    src={`/api/companies/${company.id}/logo.png?version=${company.logoVersion}`}
                                  />
                                </>
                              )}
                              <Card.Content extra>
                                <Button size="mini" color="olive" onClick={() => setEditingLogo(true)} type="button">
                                  Upload new logo
                                </Button>
                              </Card.Content>
                            </Card>
                          </Grid.Column>
                          <Grid.Column width={12}>
                            <Grid>
                              <Grid.Row>
                                <Grid.Column width={8}>
                                  <Form.Group grouped>
                                    <Field
                                      name="name"
                                      validate={(value) => !value && 'Your company must have a name'}
                                      label="Company Name"
                                      placeholder="e.g. Invoice co."
                                      control={Input}
                                      component={FormField}
                                    />
                                    <Field
                                      name="vatNumber"
                                      type="number"
                                      label="Tax Number"
                                      placeholder="e.g. 8883420123"
                                      control={Input}
                                      component={FormField}
                                    />
                                    <Field
                                      name="website"
                                      label="Website"
                                      placeholder="e.g. www.example.com"
                                      control={Input}
                                      component={FormField}
                                    />
                                    <Field
                                      name="financialYearStart"
                                      label="Financial Year"
                                      control={Dropdown}
                                      options={financialYearStarts}
                                      component={FormField}
                                    />
                                    <Field
                                      name="bankDetails"
                                      label="Bank Details"
                                      placeholder={`My Bank\nCheque Account\nAcc #: 12345678\nBranch #: 12345`}
                                      control={TextArea}
                                      rows={7}
                                      component={FormField}
                                    />
                                  </Form.Group>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Form.Group grouped>
                                    <Field
                                      name="contactName"
                                      label="Contact Person"
                                      placeholder="e.g. James Almost"
                                      validate={(value) => !value && 'Your company must have a contact person'}
                                      control={Input}
                                      component={FormField}
                                    />
                                    <Field
                                      name="contactEmail"
                                      label="Contact Email"
                                      placeholder="e.g. james@example.com"
                                      validate={(value) => !value && 'Your company must have a contact email'}
                                      control={Input}
                                      component={FormField}
                                    />
                                    <Field
                                      name="contactNumber"
                                      label="Contact Number"
                                      placeholder="e.g. 002 001 0023"
                                      control={Input}
                                      component={FormField}
                                    />
                                    <Field name="country" label="Country" control={Dropdown} options={countries} component={FormField} />
                                    <Field
                                      name="address"
                                      label="Address"
                                      placeholder={`ACME Inc.\n1007 Mountain Drive\nGotham\n`}
                                      control={TextArea}
                                      rows={7}
                                      component={FormField}
                                    />
                                  </Form.Group>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width="16">
                            <Button.Group floated="right">
                              <Button type="button" disabled={!dirty} onClick={() => form.reset()}>
                                Reset
                              </Button>
                              <Button type="submit" color="olive" loading={submitting}>
                                Save
                              </Button>
                            </Button.Group>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          );
        }}
      />
    </div>
  );
});
