import React, { forwardRef, useContext } from 'react';
import classnames from 'classnames';
import { Redirect } from 'react-router';
import { hydrate } from 'react-dom';
import { Form, Message, Button, Segment, Header } from 'semantic-ui-react';
import { company } from '../../lib/api/companies';
import { Field } from 'react-final-form';
import { EmailInput, PasswordInput, FormField } from '../../shared/Fields';
import { validatePassword } from '../../lib/validation';
import { Link } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import { UserContext } from '../../contexts/UserContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { request } from '../../lib/api/base';
import { DelayedRedirect } from '.';

export interface ForgotPasswordProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const ForgotPassword = forwardRef<HTMLDivElement, ForgotPasswordProps>(({ children, className, ...props }, ref) => {
  const { user, hydrate } = useContext(UserContext);

  return (
    <div {...props} ref={ref} className={classnames('', {}, className)}>
      <Header as="h3" color="teal" textAlign="center">
        Forgot password
      </Header>
      <FinalForm<{ email: string }>
        onSubmit={async (values) => {
          try {
            const response = await request('/identity/password-reset/request', {
              method: 'POST',
              body: JSON.stringify(values),
            });
            const statusCode = response.status;
            const data = await response.json();

            if (statusCode !== 200) {
              throw new Error('Failed');
            }
            if (data.message) {
              return {
                status: statusCode,
                ...data,
              };
            }
            await hydrate();
          } catch (e) {
            return {
              message: "We weren't able to log you in, please try again later",
            };
          }
        }}
        validate={(values) => {
          if (!values.email) {
            return {
              email: 'You need to provide us with an email address',
            };
          }
        }}
        render={({ handleSubmit, submitting, submitErrors }) => {
          return (
            <Form size="large" onSubmit={handleSubmit}>
              {submitErrors && submitErrors.status !== 200 && (
                <Message header="Oops! something seems to be broken" content={submitErrors.message} />
              )}
              {submitErrors && submitErrors.status === 200 ? (
                <>
                  <Message content={submitErrors.message} />
                  <DelayedRedirect wait={3000} to="/" />
                </>
              ) : (
                <Segment>
                  <Field name="email" placeholder="you@example.com" control={EmailInput} component={FormField} />

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color="teal" type="submit" disabled={submitting} loading={submitting} size="large">
                      Send reset link
                    </Button>
                  </div>
                </Segment>
              )}
            </Form>
          );
        }}
      />
      <Message>
        Looking for login? <Link to="/login">Here you go</Link>
      </Message>
    </div>
  );
});
