import styled, { css } from 'styled-components';
import { Segment as SuiSegment, SegmentProps } from 'semantic-ui-react';

export const PadlessSegment = styled(SuiSegment)`
  && {
    padding: 0;
    border: none;
  }
`;

export const Segment = styled(SuiSegment)<{ dark?: boolean; padless?: boolean }>`
  && {
    ${(props) =>
      props.dark &&
      css`
        background-color: #f9fafb;
      `}
    ${(props) =>
      props.padless &&
      css`
        padding: 0;
      `}
  }
`;
