import React, { forwardRef, useContext } from 'react';
import classnames from 'classnames';
import { match } from 'react-router';
import { CompanyContext, Company } from '../../contexts/CompanyContext';
import { CustomerContext } from '../../contexts/CustomerContext';
import { Notice } from '../../shared/Notice';
import { Button, Icon, List, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Margin = {
  margin?: {
    top?: boolean;
    bottom?: boolean;
    around?: boolean;
  };
};

const Wrapper = styled.div<Margin>`
  ${(props) => props.margin && props.margin.around && 'margin: 1.75em 0;'}
  ${(props) => props.margin && props.margin.top && 'margin-top: 1.75em;'}
  ${(props) => props.margin && props.margin.bottom && 'margin-bottom: 1.75em;'}
`;

export type CustomerCheckProps = React.HtmlHTMLAttributes<HTMLDivElement> & Margin & {};

export const CustomerCheck = forwardRef<HTMLDivElement, CustomerCheckProps>(({ children, className, ...props }, ref) => {
  const { customers, loading: customerLoading } = useContext(CustomerContext);
  if (customerLoading.loaded && !customers.length) {
    return (
      <Wrapper {...props} ref={ref} className={classnames('', {}, className)}>
        <Grid columns="1">
          <Grid.Row>
            <Grid.Column>
              <Notice color="olive" message="You don't have any customers yet!">
                <Button icon labelPosition="right" color="olive" as={Link} to="/customers/create">
                  <Icon name="add circle" />
                  Add one now
                </Button>
              </Notice>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Wrapper>
    );
  }
  return null;
});
