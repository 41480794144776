import React, { forwardRef, useContext, useEffect, FC } from 'react';
import classnames from 'classnames';
import { UserContext } from './contexts/UserContext';
import { Redirect, Route } from 'react-router-dom';
import { Subscription } from './pages/profile/subscription';

export const TrailGaurd: FC = ({ children, ...props }) => {
  const { user, loading } = useContext(UserContext);

  // if (loading.loaded && user.companyDetails.subscription == 'Starter' && (user.companyDetails.daysLeftInTrial || 0) <= 0) {
  //   return <Route component={Subscription} />;
  // }

  return <>{children}</>;
};
