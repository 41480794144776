import React, { forwardRef, useContext } from 'react';
import classnames from 'classnames';
import { Switch, Route } from 'react-router';
import { CompaniesFetcher, CompaniesProvider } from '../../contexts/admin/Companies';
import Layout from '../../layout';
import { CustomersFetcher, CustomersProvider } from '../../contexts/admin/Customers';
import { Dashboard } from './Dashboard';
import { UserContext } from '../../contexts/UserContext';

export interface AdminProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const Admin = forwardRef<HTMLDivElement, AdminProps>(({ children, className, ...props }, ref) => {
  const { user } = useContext(UserContext);
  if (!user.roles.includes('admin')) {
    return null;
  }
  return (
    <div {...props} ref={ref} className={classnames('', {}, className)}>
      <CustomersProvider>
        <CompaniesProvider>
          <Layout>
            <Switch>
              <Route path="/admin/dashboard" component={Dashboard} />
            </Switch>
          </Layout>
        </CompaniesProvider>
      </CustomersProvider>
    </div>
  );
});
