import React, { forwardRef, useContext, useState } from 'react';
import classnames from 'classnames';
import { match, Switch, Route } from 'react-router';
import { Segment } from '../../../shared/Segments';
import styled, { css } from 'styled-components';
import { profileColors } from '../shared';
import { Label, Button, Icon, List, Loader, Message } from 'semantic-ui-react';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { subscribe } from '../../../lib/api/companies';
import { UserContext } from '../../../contexts/UserContext';
import { Notice } from '../../../shared/Notice';
import { Link } from 'react-router-dom';
import { LoadingWrapper } from '../../../shared/Loading';
import { Subscribe, ConfirmSubscribe } from './Subscribe';
import { Subscriptions } from './Subscriptions';

export interface SubscriptionProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match;
}

export const Subscription = forwardRef<HTMLDivElement, SubscriptionProps>(({ match, children, className, ...props }, ref) => {
  return (
    <Switch>
      <Route path="/profile/subscribe/:subscription/confirm/:card_id" component={ConfirmSubscribe} />
      <Route path="/profile/subscribe/:subscription" component={Subscribe} />
      <Route component={Subscriptions} />
    </Switch>
  );
});
