import { Person } from './people';
import { request } from './base';

export type Company = {
  id: string;
  name: string;
  vatNumber: string;
  website: string;
  paymentMethod: string;
  bankDetails: string;
  address: string;
  contactName: string;
  country: string;
  contactEmail: string;
  contactNumber: string;
  complete?: boolean;
  subscription: string;
  logoVersion: string;
  financialYearStart: number;
  logo?: any;
  trialOver: boolean;
  daysLeftInTrial: number;
  paymentDate?: number;
};

export const company = async (id: string): Promise<Company> => {
  const response = await request(`/companies/${id}`);
  const { data } = await response.json();
  return data;
};

export const updateCompany = async (id: string, company: Partial<Company>) => {
  const formData = new FormData();

  Object.keys(company).forEach((key: any) => {
    if ((company as any)[key]) {
      formData.append(key, (company as any)[key]);
    }
  });

  return request(`/companies/${id}`, {
    method: 'put',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  });
};

export const invite = async (id: string, member: { displayName: string; email: string }) => {
  return request(`/companies/${id}/invite`, {
    method: 'POST',
    body: JSON.stringify(member),
  });
};

export const revokeInvite = async (company: string, id: string) => {
  return request(`/companies/${company}/invite/${id}`, {
    method: 'DELETE',
  });
};

export const subscribe = async (id: string, subscription: string) => {
  return request(`/companies/${id}/subscribe`, {
    method: 'POST',
    body: JSON.stringify({ subscription }),
  });
};
export const company_people = async (company: string): Promise<Person[]> => {
  const response = await request(`/companies/${company}/people`);
  const { data } = await response.json();
  return data;
};

export const removeCustomer = async (company: string, id: string) => request(`/companies/${company}/customers/${id}`, { method: 'DELETE' });

export const archiveCustomer = async (company: string, id: string) =>
  request(`/companies/${company}/customers/${id}/archive`, { method: 'POST' });
