import { request } from './base';
import { Person } from './people';

export type Customer = {
  id: string;
  name: string;
  category: string;
  vatNumber: string;
  company: string;
  currency: string;
  invoiceDate: Date;
  website: string;
  address: string;
  defaultNote: string;
  defaultDiscount: string;
  paymentTerms: number;
  vatRate: number;
  contactPersonDisplayName: string;
  people: number;
  balance: number;
  lastInvoice: Date;
  contactName: string;
  contactNumber: string;
  contactEmail: string;
  customerEmail: string;
  invoices: number;
};

export const customers = async (company: string) => {
  const response = await request(`/companies/${company}/customers`);
  if (response.status == 500) {
    throw new Error('Failed to load customers!');
  }
  const { data } = await response.json();
  return data as Customer[];
};

export const create = async (company: string, customer: Customer) => {
  return request(`/companies/${company}/customers`, {
    method: 'POST',
    body: JSON.stringify(customer),
  });
};

export const update = async (company: string, id: string, customer: Customer) => {
  return request(`/companies/${company}/customers/${id}`, {
    method: 'PUT',
    body: JSON.stringify(customer),
  });
};

export const people = async (company: string, id: string) => {
  const response = await request(`/companies/${company}/customers/${id}/people`);
  if (response.status == 500) {
    throw new Error('Failed to load people!');
  }
  const { data } = await response.json();
  return data as Person[];
};

export const createPerson = async (company: string, customer: string, person: Person) => {
  return request(`/companies/${company}/customers/${customer}/people`, {
    method: 'POST',
    body: JSON.stringify(person),
  });
};

export const updatePerson = async (company: string, customer: string, id: string, person: Partial<Customer>) => {
  return request(`/companies/${company}/customers/${customer}/people/${id}`, {
    method: 'PUT',
    body: JSON.stringify(person),
  });
};

export const removeCustomerPerson = async (company: string, customer: string, id: string) => {
  return request(`/companies/${company}/customers/${customer}/people/${id}`, {
    method: 'DELETE',
  });
};
