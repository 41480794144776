import React, { createContext, useState, FC, useMemo } from 'react';
import { Card, cards as getCards, removeCard, BillingHistory, billingHistory } from '../lib/api/billing';

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

const defaultCards: Card[] = [];

export type BillingContextState = {
  cards: Card[];
  history: BillingHistory[];
  loading: Loading;
  hydrate: (company: string) => Promise<void>;
  remove: (company: string, id: string) => Promise<void>;
};

export const BillingContext = createContext<BillingContextState>({
  cards: defaultCards,
  history: [],
  loading: { loading: false, loaded: false, error: undefined },
  hydrate: () => Promise.resolve(),
  remove: () => Promise.resolve(),
});

export const BillingProvider: FC = ({ children }) => {
  const [cards, setCards] = useState<Card[]>(defaultCards);
  const [history, setHistory] = useState<BillingHistory[]>([]);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined });
  const hydrate = async (company: string) => {
    try {
      setLoading({
        loaded: false,
        loading: true,
        error: undefined,
      });
      const [cards, history] = await Promise.all([getCards(company), billingHistory(company)]);
      setCards(cards);
      setHistory(history);
      setLoading({
        loaded: true,
        loading: false,
        error: undefined,
      });
    } catch (e) {
      setLoading({
        loaded: false,
        loading: false,
        error: e,
      });
    }
  };
  const value = useMemo(
    () => ({
      cards,
      history,
      loading,
      setLoading,
      hydrate,
      remove: async (company: string, id: string) => {
        await removeCard(company, id);
        await hydrate(company);
      },
    }),
    [cards, hydrate, loading],
  );

  return <BillingContext.Provider value={value}>{children}</BillingContext.Provider>;
};
