import React, { forwardRef, useContext } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { CustomerContext } from '../../../contexts/CustomerContext';
import { Link, Switch, Route, match } from 'react-router-dom';
import { CustomerList } from './CustomerList';
import { CustomerForm } from './Form';
import { CustomerPeople } from './CustomerPeople';
import { EnsureCustomerPeople } from '../../../fetchers/EnsureCustomerPeople';

export interface CustomersProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match;
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Customers = forwardRef<HTMLDivElement, CustomersProps>(({ match, children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <Switch>
        <Route path="/customers/edit/:id" render={({ match }) => <CustomerForm match={match} editing />} />
        <Route path="/customers/:id/people" component={CustomerPeople} />
        <Route path="/customers/create" component={CustomerForm} />
        <CustomerList />
      </Switch>
    </div>
  );
});
