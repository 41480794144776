import React, { forwardRef, useState } from 'react';
import classnames from 'classnames';
import { extractValidationError } from '../../lib/utils';
import { Form, Message, Segment, Input, Button, Header } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import { EmailInput, PasswordInput, FormField, Dropdown } from '../../shared/Fields';
import { validatePassword } from '../../lib/validation';
import { Link } from 'react-router-dom';
import { request } from '../../lib/api/base';
import { Form as FinalForm } from 'react-final-form';
import { countries } from '../../lib/countries';
import { getCurrencyByCountry } from '../../lib/currencies';

export interface SignUpProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const SignUp = forwardRef<HTMLDivElement, SignUpProps>(({ children, className, ...props }, ref) => {
  const [registrationSucceeded, setRegistrationSucceeded] = useState<boolean>(false);

  return (
    <div {...props} ref={ref} className={classnames('', {}, className)}>
      <FinalForm<{ email: string; password: string; displayName: string; country: { value: string; label: string } }>
        onSubmit={async (values) => {
          try {
            const response = await request('register', {
              method: 'POST',
              body: JSON.stringify({
                ...values,
                country: values.country.value,
                currency: getCurrencyByCountry(values.country.value) || 'USD', // Default's to USD if no currency is found.
              }),
            });
            const data = await response.json();

            if (response.status == 422) {
              const messages: { [index: string]: string[] } = await response.json();

              return extractValidationError(messages);
            }

            if ((response.status == 500) == data.message) {
              return data;
            }

            if (response.status !== 200) {
              throw new Error('Failed');
            }
            setRegistrationSucceeded(true);
          } catch (e) {
            return {
              message: "We weren't able to register you, please try again later",
            };
          }
        }}
        validate={(values) => {
          if (!values.email) {
            return {
              email: 'You need to provide us with an email address',
            };
          }
        }}
        render={({ handleSubmit, values, submitting, submitSucceeded, submitErrors }) => {
          return (
            <>
              {submitSucceeded ? (
                <Header as="h3" color="teal" textAlign="center">
                  Welcome to Vulcan Invoicing!
                </Header>
              ) : (
                <Header as="h3" color="teal" textAlign="center">
                  Sign up for your vulcan account
                </Header>
              )}
              <Form size="large" onSubmit={handleSubmit}>
                {submitErrors && <Message header="Oops! something seems to be broken" content={submitErrors.message} />}
                {submitSucceeded ? (
                  <>
                    <Message content={`Please check your email to activate your account`} />
                  </>
                ) : (
                  <Segment>
                    <Field
                      name="displayName"
                      placeholder="What should we call you?"
                      validate={(value) => !value && 'You need to provide us with a display name'}
                      control={Input}
                      icon="user"
                      iconPosition="left"
                      component={FormField}
                    />
                    <Field
                      name="company"
                      placeholder="What is the name of your company?"
                      validate={(value) => !value && 'You need to provide us with a company name'}
                      control={Input}
                      icon="user"
                      iconPosition="left"
                      component={FormField}
                    />
                    <Field name="email" placeholder="you@example.com" control={EmailInput} component={FormField} />

                    <Field
                      name="country"
                      placeholder="Which country are you from"
                      validate={(value) => {
                        if (!value) {
                          return 'Required';
                        }
                      }}
                      control={Dropdown}
                      options={countries}
                      component={FormField}
                    />
                    <Field
                      name="password"
                      placeholder="Password"
                      validate={validatePassword}
                      control={PasswordInput}
                      component={FormField}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button color="teal" type="submit" disabled={submitting} loading={submitting} size="large">
                        Sign up
                      </Button>
                    </div>
                  </Segment>
                )}
              </Form>
            </>
          );
        }}
      />
      {!registrationSucceeded && (
        <>
          <Message>
            <b>PROTIP:</b> If your password is longer than 14 charcaters you don't need any special rules
          </Message>
          <Message>
            Already have an account? Sign in {` `} <Link to="/login">here</Link>
          </Message>
        </>
      )}
    </div>
  );
});
