import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { Table, TableBody, Row, Cell, TableHeader, TableFooter } from '../../components/table';
import { css } from 'styled-components';

export interface ScratchProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const Scratch = forwardRef<HTMLDivElement, ScratchProps>(({ children, className, ...props }, ref) => {
  return <div {...props} ref={ref} className={classnames('', {}, className)}></div>;
});
