import React, { createContext, useState, FC, useMemo, useCallback, useContext, useEffect } from 'react';
import { Endpoint, Query } from '../lib/api/base';
import { history } from '../lib/history';

export type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

export type ResourcePage<T> = Query & {
  items: T[];
  total: number;
  pages: number;
};

export type ResourceContext<T> = {
  loading: Loading;
  page: ResourcePage<T>;
  loadPage: (query: Partial<Query>) => Promise<void>;
  create: (item: Partial<T>, include: string[]) => Promise<Partial<T>>;
  update: (id: string, item: Partial<T>, include: string[]) => Promise<Partial<T>>;
};

export function createResourceContext<T>(
  endpoint: Endpoint<T>,
  options: {
    defaultSort?: { [column: string]: 'asc' | 'desc' };
    includes?: string[];
  } = {},
) {
  const Context = createContext<ResourceContext<T>>({
    loading: {
      loading: false,
      loaded: false,
      error: undefined,
    },
    page: {
      page: {
        size: 0,
        number: 0,
      },
      include: [],
      total: 0,
      pages: 0,
      sort: {},
      items: [],
      where: {},
    },
    loadPage: () => Promise.resolve(),
    create: () => Promise.resolve({}),
    update: () => Promise.resolve({}),
  });

  const Provider: FC = ({ children }) => {
    const [total, setTotal] = useState(0);
    const [query, setQueryState] = useState<Query>({
      include: options.includes || [],
      page: {
        number: 0,
        size: 50,
      },
      sort: options.defaultSort || {},
      where: {},
    });
    const [items, setItems] = useState<T[]>([]);
    const [loading, setLoading] = useState<Loading>({
      loading: false,
      loaded: false,
      error: undefined,
    });

    const loadPage = useCallback(
      async (where: Partial<Query>) => {
        try {
          setLoading({
            loading: true,
            loaded: loading.loaded,
            error: undefined,
          });
          const { data, meta } = await endpoint.all({
            ...query,
            ...where,
          });
          setItems(data);
          setTotal(meta.total);
          setQueryState({ ...query, ...where });
          setLoading({
            loading: false,
            loaded: true,
            error: undefined,
          });
        } catch (e) {
          if (e.isApiError && e.response.status == 401) {
            history.push(`/login`);
            setLoading({
              loading: false,
              loaded: loading.loaded,
              error: undefined,
            });
          } else {
            setLoading({
              loading: false,
              loaded: loading.loaded,
              error: e,
            });
          }
        }
      },
      [setQueryState, setLoading, setItems, query, setTotal, loading],
    );

    const create = useCallback(async (resource: Partial<T>, include: string[]): Promise<T> => endpoint.create(resource, include), []);

    const update = useCallback(
      async (id: string, resource: Partial<T>, include: string[]): Promise<T> => endpoint.update(id, resource, include),
      [],
    );

    const value = useMemo(
      () => ({
        page: {
          ...query,
          items,
          total,
          pages: total > 0 ? Math.ceil(total / (query.page.size + 1)) : 1,
        },
        loading,
        loadPage,
        create,
        update,
      }),
      [query, total, loadPage, create, update, items, loading],
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  const Fetcher: FC = () => {
    const { loading, loadPage, page } = useContext(Context);
    useEffect(() => {
      if (!loading.loaded && !loading.loading && !loading.error) {
        loadPage({});
      }
    }, [loading, page, loadPage]);
    return null;
  };

  return { Context, Provider, Fetcher };
}
