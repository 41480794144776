import React, { forwardRef, useContext, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHeaderCell,
  Segment as SemanticSegment,
  TableCell,
  Button,
  Icon,
  Loader,
  Message,
} from 'semantic-ui-react';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { CustomerContext } from '../../../contexts/CustomerContext';
import { Link } from 'react-router-dom';
import { LoadingWrapper } from '../../../shared/Loading';
import { Notice } from '../../../shared/Notice';
import { hydrate } from 'react-dom';
import { UserContext } from '../../../contexts/UserContext';
import { Section } from '../../../shared/Header';
import BigNumber from 'bignumber.js';
import { currencies } from '../../../lib/currencies';
import { archiveCustomer } from '../../../lib/api/companies';

export interface CustomerListProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Segment = styled(SemanticSegment)`
  && {
    padding: 0;
    border: none;
  }
`;
const userCounts: { [index: string]: any } = {
  Starter: 2,
  Booming: null,
};
export const CustomerList = forwardRef<HTMLDivElement, CustomerListProps>(({ children, className, ...props }, ref) => {
  const { user } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const { customers, loading, hydrate, remove } = useContext(CustomerContext);
  const maxCustomers = userCounts[company.subscription] ? userCounts[company.subscription] <= customers.length : false;
  const [toRemove, setToRemove] = useState('');

  if (loading.error) {
    return (
      <Notice icon="exclamation circle" message="Failed to load your customers!">
        <Button onClick={() => hydrate(user.company || '')}>Try again</Button>
      </Notice>
    );
  }
  if (loading.loading || !loading.loaded) {
    return (
      <LoadingWrapper {...props} ref={ref} className={classnames('', {}, className)}>
        <Loader size="medium" active inline>
          Loading your customers...
        </Loader>
      </LoadingWrapper>
    );
  }
  if (customers.length <= 0) {
    return (
      <Notice color="olive" icon="list alternate outline" message="You dont have any customers yet">
        <Button icon labelPosition="right" color="olive" as={Link} to="/customers/create">
          <Icon name="add circle" />
          Add one now
        </Button>
      </Notice>
    );
  }
  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <Section>
        <h3>Customers</h3>
        <Button icon labelPosition="right" color="olive" as={Link} to="/customers/create" disabled={maxCustomers}>
          <Icon name="add circle" />
          Add a new customer
        </Button>
      </Section>
      {maxCustomers && (
        <Message>
          You are only allowed up to {userCounts[company.subscription]} users on your current subscription. A purchased subscription allows
          you to create an unlimited amount of customers.
        </Message>
      )}
      <Segment color="olive">
        <Table fixed sortable celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell width="four">Name</TableHeaderCell>
              <TableHeaderCell width="four">Primary Contact</TableHeaderCell>
              <TableHeaderCell width="one">People</TableHeaderCell>
              <TableHeaderCell width="one">Invoices</TableHeaderCell>
              <TableHeaderCell width="two"></TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell>{customer.name}</TableCell>
                <TableCell>{customer.contactPersonDisplayName}</TableCell>
                <TableCell textAlign="right">
                  <Link to={`/customers/${customer.id}/people`}>{customer.people}</Link>
                </TableCell>
                <TableCell textAlign="right">
                  <Link to={`/invoices?customer=${customer.id}`}>{customer.invoices}</Link>
                </TableCell>
                <TableCell>
                  <Button.Group size="mini" floated="right">
                    {toRemove == customer.id ? (
                      <>
                        <Button
                          size="mini"
                          color="pink"
                          onClick={async () => {
                            await archiveCustomer(customer.company, customer.id);
                            await hydrate(user.company);
                            setToRemove('');
                          }}
                        >
                          <Icon name="pencil" />
                          Confirm
                        </Button>
                        <Button size="mini" onClick={() => setToRemove('')} color="olive">
                          <Icon name="x" />
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button as={Link} to={`/customers/edit/${customer.id}`} size="mini" color="olive">
                          <Icon name="pencil" />
                          Edit
                        </Button>
                        <Button size="mini" onClick={() => setToRemove(customer.id)} color="grey">
                          <Icon name="file" />
                          Archive
                        </Button>
                      </>
                    )}
                  </Button.Group>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Segment>
    </div>
  );
});
