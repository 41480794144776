import React, { forwardRef, useState, useContext, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { match, Route } from 'react-router';
import { ReportForm } from './Form';
import { ReportResults } from './ReportResults';
import { UserContext } from '../../contexts/UserContext';
import { InvoiceContext } from '../../contexts/InvoiceContext';
import { useQueryParams, StringParam, ArrayParam, NumberParam } from 'use-query-params';

export interface ReportsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match;
}

export const Reports = forwardRef<HTMLDivElement, ReportsProps>(({ match, children, className, ...props }, ref) => {
  const { user } = useContext(UserContext);
  const { invoices, hydrate, loading, total: totalInvoices } = useContext(InvoiceContext);

  const [batchOperationIds, setBatchOperationIds] = useState<{ [id: string]: boolean }>({});

  const [filter, setFilter] = useQueryParams({
    customer: StringParam,
    status: ArrayParam,
    from_date: StringParam,
    to_date: StringParam,
    page: NumberParam,
    pageSize: NumberParam,
    period: StringParam,
  });

  useEffect(() => {
    if (user.company) {
      hydrate(
        user.company,
        { size: filter.pageSize || 50, number: (filter.page || 1) - 1 },
        {
          customer: filter.customer,
          due_from: filter.from_date,
          due_to: filter.to_date,
          status: filter.status,
        },
      );
    }
  }, [filter, user]);

  const showResult = filter.status && filter.from_date && filter.to_date;

  const reportShardedContext = useMemo(
    () => ({
      filters: filter,
      setFilters: setFilter,
      hydrate,
      invoices,
      batchOperationIds,
      setBatchOperationIds,
    }),
    [filter, invoices, batchOperationIds],
  );

  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <Route render={({ match }) => <ReportForm {...reportShardedContext} userCompany={user.company} />} />
      {showResult && (
        <Route render={({ match }) => <ReportResults {...reportShardedContext} loading={loading} totalInvoices={totalInvoices} />} />
      )}
    </div>
  );
});
