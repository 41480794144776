import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router';
import { UserContext } from '../contexts/UserContext';
import { CompanyContext } from '../contexts/CompanyContext';

export const EnsureCompany: React.FunctionComponent<any> = ({ match }) => {
  const { user, loading: userLoading } = useContext(UserContext);
  const { loading, hydrate } = useContext(CompanyContext);

  useEffect(() => {
    if (!loading.loading && !loading.loaded && !loading.error && userLoading.loaded && user.company) hydrate(user.company || '');
  }, [user, hydrate, loading, userLoading]);

  return null;
};
