import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router';
import { UserContext } from '../contexts/UserContext';

export const EnsureUser: React.FunctionComponent<any> = ({ match }) => {
  const { user, hydrate, loading } = useContext(UserContext);

  useEffect(() => {
    if (!loading.loading && !loading.loaded && !loading.error) hydrate();
  }, [user, hydrate, loading]);

  if (loading.error || (loading.loaded && !user.id)) {
    return <Redirect to="/login" />;
  }

  return null;
};
