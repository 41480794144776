import { request, apiHeaders, formDataSize } from './base';
import { Company } from './companies';

export type User = {
  id: string;
  email: string;
  displayName: string;
  firstName: string;
  lastName: string;
  company: string;
  companyName: string;
  language: string;
  contactNumber: string;
  imageVersion: string;
  companies: Company[];
  roles: string[];
};

export const whoami = (): Promise<User> => request('/identity/whoami').then((res) => res.json());

export const login = async (fields: { email: string; password: string; market: string }) => {
  const response = await request('/identity/login', {
    method: 'POST',
    body: JSON.stringify(fields),
  });
  return response.json();
};

export const setCompany = async (company: string) => {
  return request(`/identity/company/${company}`, {
    method: 'POST',
  });
};

export const resetPasswrod = async (creds: { password: string; confirmPassword: string }) => {
  return request('/identity/password-reset/authenticated', {
    method: 'POST',
    body: JSON.stringify(creds),
  });
};

export const updateUser = async (user: User) => {
  const formData = new FormData();

  Object.keys(user).forEach((key: any) => {
    if ((user as any)[key]) {
      formData.append(key, (user as any)[key]);
    }
  });

  return request('/identity', {
    method: 'put',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  });
};
