import React, { createContext, useState, FC, useMemo, useContext } from 'react';
import { invoices as getInvoices, Invoice } from '../lib/api/invoices';

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

const defaultInvoices: Invoice[] = [];

export type InvoiceContextState = {
  invoices: Invoice[];
  total: number;
  outstanding: { outstanding: Outstanding[] };
  loading: Loading;
  hydrate: (company: string, page?: { number: number; size: number }, where?: { [index: string]: any }) => Promise<void>;
};

export type Outstanding = {
  month: string;
  total: number;
};

export type OutstandingByCustomer = {
  customer: string;
  total: number;
};

export const InvoiceContext = createContext<InvoiceContextState>({
  outstanding: { outstanding: [] },
  invoices: defaultInvoices,
  total: 0,
  loading: { loading: false, loaded: false, error: undefined },
  hydrate: (company: string, page?: { number: number; size: number }, where: { [index: string]: any } = {}) => Promise.resolve(),
});

export const InvoiceProvider: FC = ({ children }) => {
  const [outstanding, setOutstanding] = useState<{ outstanding: Outstanding[] }>({
    outstanding: [],
  });
  const [total, setTotal] = useState(0);
  const [invoices, setInvoice] = useState<Invoice[]>(defaultInvoices);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined });

  const value = useMemo(
    () => ({
      invoices,
      total,
      outstanding,
      loading,
      setLoading,
      hydrate: async (
        company: string,
        page: {
          number: number;
          size: number;
        } = {
          number: 0,
          size: 50,
        },
        where: { [index: string]: any } = {},
      ) => {
        try {
          setLoading({
            loaded: loading.loaded,
            loading: true,
            error: undefined,
          });
          const res = await getInvoices(company, page, where);
          const {
            data: { invoices, outstanding },
            meta: { total },
          } = await res.json();
          setTotal(total);
          setInvoice(
            invoices.map((inv: any) => {
              inv.sent = new Date(inv.sent);
              inv.issued = new Date(inv.issued);
              inv.due = new Date(inv.due);
              return inv;
            }),
          );
          setOutstanding(outstanding);
          setLoading({
            loaded: true,
            loading: false,
            error: undefined,
          });
        } catch (e) {
          setLoading({
            loaded: false,
            loading: false,
            error: e,
          });
        }
      },
    }),
    [invoices, loading, setInvoice, setLoading],
  );

  return <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>;
};
