import React, { forwardRef, useContext, useState } from 'react';
import classnames from 'classnames';
import { Message, Loader, Grid, Button, ButtonGroup, Segment, Icon } from 'semantic-ui-react';
import CreditCard from 'react-credit-cards';
import { Section } from '../../../shared/Header';
import { AddCard, CardHolder } from '../billing';
import { LoadingWrapper } from '../../../shared/Loading';
import { BillingContext } from '../../../contexts/BillingContext';
import { UserContext } from '../../../contexts/UserContext';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { subscribe } from '../../../lib/api/companies';
import { setAsDefault } from '../../../lib/api/billing';
import { match } from 'react-router';
import { history } from '../../../lib/history';
import styled from 'styled-components';

export interface SubscribeProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match<{ subscription: string }>;
}

export interface ConfirmSubscribeProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match<{
    subscription: string;
    card_id: string;
  }>;
}

const StyledCardHolder = styled(CardHolder)`
  cursor: pointer;
  margin-right: 1em;
`;

const CardList = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const productCost: { [key: string]: string } = {
  Booming: 'R795 p/y',
};

export const ConfirmSubscribe = forwardRef<HTMLDivElement, ConfirmSubscribeProps>(({ children, className, match, ...props }, ref) => {
  const { cards, loading, hydrate } = useContext(BillingContext);
  const { hydrate: hydrateCompany } = useContext(CompanyContext);
  const { user, hydrate: hydrateUser } = useContext(UserContext);

  if (!loading.loaded) {
    return (
      <LoadingWrapper>
        <Loader size="medium" active inline>
          Fetching your card...
        </Loader>
      </LoadingWrapper>
    );
  }

  const [error, setError] = useState('');
  const [paymentLoader, setPaymentLoader] = useState<boolean>(false);

  const selectedcard = cards.find((card) => card.id === match.params.card_id);

  return (
    <div {...props} ref={ref} className={classnames('', {}, className)}>
      <Section style={{ marginBottom: '1em', marginTop: '1em' }}>
        <h3>Confirm Payment</h3>
      </Section>
      {error && <Message warn>{error}</Message>}
      {selectedcard && (
        <Grid>
          <Grid.Row>
            <Grid.Column width="5">
              <StyledCardHolder key={selectedcard.id} onClick={() => null}>
                <CreditCard number={selectedcard.identifier} cvc="" name={selectedcard.holder} expiry={selectedcard.expiry} />
              </StyledCardHolder>
            </Grid.Column>
            <Grid.Column width="9">
              <div style={{ marginTop: '2em' }}>
                <h4>
                  {`You are about to sign up for the ${match.params.subscription} package at ${productCost[match.params.subscription]}.`}{' '}
                </h4>
                <h5>{`Please confirm a payment of ${productCost[match.params.subscription]}`} </h5>
                <br />
                <Button
                  color="olive"
                  loading={paymentLoader}
                  disabled={paymentLoader}
                  onClick={async () => {
                    try {
                      setError('');
                      setPaymentLoader(true);

                      await setAsDefault(user.company, selectedcard.id);
                      const response = await subscribe(user.company, match.params.subscription);

                      if (response.status !== 200) {
                        throw new Error("We weren't able to complete your subscription. Please try again later.");
                      }

                      Promise.all([hydrate(user.company), hydrateCompany(user.company), hydrateUser()]).then((data) =>
                        history.push('/profile/billing'),
                      );
                    } catch (e) {
                      setPaymentLoader(false);
                      setError(e.message);
                    }
                  }}
                >
                  <Icon name="credit card" />
                  Process Payment
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
});

export const Subscribe = forwardRef<HTMLDivElement, SubscribeProps>(({ children, className, match, ...props }, ref) => {
  const { cards, loading } = useContext(BillingContext);

  const noCard = Array.isArray(cards) && cards.length === 0;

  if (!loading.loaded) {
    return (
      <LoadingWrapper>
        <Loader size="medium" active inline>
          Fetching your cards...
        </Loader>
      </LoadingWrapper>
    );
  }

  if (noCard) {
    return (
      <>
        <Message info>Hmm... Seems there are no payment details associated to your account. Add a new card below.</Message>
        <AddCard redirectAfterSubmit={false} />
      </>
    );
  }

  const [addNewCard, setAddNewCard] = useState<boolean>(false);

  return (
    <div {...props} ref={ref} className={classnames('', {}, className)}>
      <Section style={{ marginBottom: '1em' }}>
        <h3> {addNewCard ? 'Add card for Payment' : 'Select card for Payment'} </h3>
        <Grid columns="1">
          <Grid.Row>
            <Grid.Column>
              <Button.Group size="tiny" floated="right">
                <Button color="olive" onClick={() => setAddNewCard(!addNewCard)}>
                  <Icon name="credit card" />
                  {addNewCard ? 'Cancel' : 'Add New Card'}
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Section>

      {addNewCard ? (
        <AddCard redirectAfterSubmit={false} />
      ) : (
        <>
          <CardList>
            {cards.map((card) => (
              <StyledCardHolder
                key={card.id}
                onClick={() => history.push(`/profile/subscribe/${match.params.subscription}/confirm/${card.id}`)}
              >
                <CreditCard number={card.identifier} cvc="" name={card.holder} expiry={card.expiry} />
              </StyledCardHolder>
            ))}
          </CardList>
        </>
      )}
    </div>
  );
});
