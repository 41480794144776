import React, { createContext, useState, FC, useMemo } from 'react';
import { Person } from '../lib/api/people';
import { people as getCustomerPeople, removeCustomerPerson } from '../lib/api/customers';

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

const defaultCustomerPeople: Person[] = [];

export type CustomerPeopleContextState = {
  people: Person[];
  loading: Loading;
  currentCustomer: string;
  hydrate: (company: string, id: string) => Promise<void>;
  remove: (company: string, customer: string, id: string) => Promise<void>;
};

export const CustomerPeopleContext = createContext<CustomerPeopleContextState>({
  people: defaultCustomerPeople,
  currentCustomer: '',
  loading: { loading: false, loaded: false, error: undefined },
  hydrate: () => Promise.resolve(),
  remove: () => Promise.resolve(),
});

export const CustomerPeopleProvider: FC = ({ children }) => {
  const [people, setCustomerPeople] = useState<Person[]>(defaultCustomerPeople);
  const [currentCustomer, setCurrentCustomer] = useState('');
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined });
  const hydrate = async (company: string, id: string) => {
    try {
      setLoading({
        loaded: false,
        loading: true,
        error: undefined,
      });
      const people = await getCustomerPeople(company, id);
      setCustomerPeople(people);
      setCurrentCustomer(id);
      setLoading({
        loaded: true,
        loading: false,
        error: undefined,
      });
    } catch (e) {
      setLoading({
        loaded: false,
        loading: false,
        error: e,
      });
    }
  };
  const value = useMemo(
    () => ({
      people,
      currentCustomer,
      loading,
      setLoading,
      hydrate,
      remove: async (company: string, customer: string, id: string) => {
        await removeCustomerPerson(company, customer, id);
        await hydrate(company, id);
      },
    }),
    [people, hydrate, loading],
  );

  return <CustomerPeopleContext.Provider value={value}>{children}</CustomerPeopleContext.Provider>;
};
