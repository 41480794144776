import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router';
import { UserContext } from '../contexts/UserContext';
import { CustomerContext } from '../contexts/CustomerContext';

export const EnsureCustomers: React.FunctionComponent<any> = ({ match }) => {
  const { user, loading: userLoading } = useContext(UserContext);
  const { loading, hydrate } = useContext(CustomerContext);

  useEffect(() => {
    if (!loading.loading && !loading.loaded && !loading.error && userLoading.loaded && user.company) hydrate(user.company || '');
  }, [user, hydrate, loading, userLoading]);

  return null;
};
