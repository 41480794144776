import { endpoint } from '../base';
import { Subscription } from './subscriptions';
import { Customer } from '../customers';
import { Person } from '../people';

export type Company = {
  id: string;
  name: string;
  vatNumber: string;
  website: string;
  contactName: string;
  contactEmail: string;
  contactNumber: string;
  paymentMethod: string;
  bankDetails: string;
  logo?: any;
  address: string;
  subscription: string | Subscription;
  customers?: Customer[];
  people?: Person[];
  financialYearStart: number;
};

export const companies = endpoint<Company>({
  endpoint: '/admin/companies',
});
