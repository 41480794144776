import React, { Component, FC, useContext } from 'react';
import './index.scss';
import { Menu, Header, Container as C, Image, Tab, Label, List, Segment, Dropdown, Icon, Button } from 'semantic-ui-react';
import { Link, useLocation, Switch, Route } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import logo from '../assets/logo.svg';
import { setCompany } from '../lib/api/user';
import { format, addYears, differenceInDays } from 'date-fns';
import styled from 'styled-components';

const Container = styled(C)`
  &&&&&&&&&& {
  }
`;

export const Layout: FC<{}> = ({ children }) => {
  const { user, hydrate } = useContext(UserContext);
  const location = useLocation();

  return (
    <div>
      <Menu fixed="top">
        <Container>
          <Menu.Item header>
            <Image size="tiny" src={logo} />
          </Menu.Item>
          <Switch>
            <Route
              path="/admin"
              render={() => (
                <>
                  <Menu.Item as={Link} to="/admin/Dashboard" active={location.pathname.includes('companies')}>
                    Dashboard
                  </Menu.Item>
                  <Menu.Item as={Link} to="/admin/invoices" active={location.pathname.includes('companies')}>
                    Invoices
                  </Menu.Item>
                  <Menu.Item as={Link} to="/admin/companies" active={location.pathname.includes('companies')}>
                    Companies
                  </Menu.Item>
                  <Menu.Item as={Link} to="/admin/customers" active={location.pathname.includes('customers')}>
                    Customers
                  </Menu.Item>
                </>
              )}
            />
            <Route
              render={() => (
                <>
                  {user.id && (
                    <>
                      <Menu.Item as={Link} to="/invoices" active={location.pathname.includes('invoices')}>
                        Invoices
                      </Menu.Item>
                      <Menu.Item as={Link} to="/reports" active={location.pathname.includes('reports')}>
                        Reports
                      </Menu.Item>
                      <Menu.Item as={Link} to="/profile/company" active={location.pathname.includes('company')}>
                        Company
                      </Menu.Item>
                      <Menu.Item as={Link} to="/customers" active={location.pathname.includes('customers')}>
                        Customers
                      </Menu.Item>
                      <Menu.Item as={Link} to="/profile/billing" active={location.pathname.includes('billing')}>
                        Billing
                      </Menu.Item>
                    </>
                  )}
                </>
              )}
            />
          </Switch>
          {user.id ? (
            <Menu.Menu position="right">
              {user.companyDetails.subscription == 'Starter' && (
                <Menu.Item link as={Link} to="/profile/subscription">
                  <Label>Starter Plan (Free)</Label>
                </Menu.Item>
              )}
              {user.companyDetails.subscription == 'Booming' && (
                <Menu.Item link as={Link} to="/profile/subscription">
                  <Label
                    title={
                      !user.companyDetails.paymentDate
                        ? ''
                        : `Annual Plan Ends in ${differenceInDays(
                            addYears(user.companyDetails.paymentDate, 1),
                            user.companyDetails.paymentDate,
                          )} days on ${format(addYears(user.companyDetails.paymentDate, 1), 'dd MMM yyyy')}`
                    }
                  >
                    Annual Plan
                  </Label>
                </Menu.Item>
              )}
              <Switch>
                <Route path="/admin" render={() => <span />} />
                <Route
                  render={() =>
                    user.companies.length > 1 && (
                      <Dropdown
                        position="right"
                        item
                        simple
                        trigger={
                          <>
                            <Icon name="building" />
                            {user.companyDetails.name}
                          </>
                        }
                      >
                        <Dropdown.Menu>
                          {user.companies
                            .filter((c) => c.id != user.company)
                            .map((company) => (
                              <Menu.Item
                                key={company.id}
                                onClick={async () => {
                                  await setCompany(company.id);
                                  await hydrate();
                                  (window as any).location = '/';
                                }}
                              >
                                {company.name}
                              </Menu.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )
                  }
                />
              </Switch>
              <Dropdown
                position="right"
                item
                simple
                trigger={
                  <>
                    <Image avatar src={`/api/identity/avatar.png?version=${user.imageVersion}`} />
                    &nbsp; Hi {user.displayName}!
                  </>
                }
              >
                <Dropdown.Menu>
                  <Menu.Item as={Link} active={location.pathname == '/profile'} to="/profile">
                    <Icon name="user" />
                    Profile
                  </Menu.Item>
                  <Menu.Item as={Link} active={location.pathname == '/profile/subscription'} to="/profile/subscription">
                    <Icon name="check" />
                    Subscription
                  </Menu.Item>
                  <Menu.Item as="a" href="/api/identity/logout">
                    <Icon name="lock" />
                    Logout
                  </Menu.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          ) : (
            <Menu.Item position="right">
              <Button as={Link} to="/login">
                Log in
              </Button>
            </Menu.Item>
          )}
        </Container>
      </Menu>
      <Container className="app__container">{children}</Container>
    </div>
  );
};

export default Layout;
